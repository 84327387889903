import { IRole } from 'src/app/core/models/User';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content, IUser, UserStore } from '../../models/models';

@Injectable()
export class UserService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Delete
   * @param userId
   */
  public delete(userId: number): Observable<IUser> {
    return this.http.delete<IUser>(
      `${this.basePath}/v1/resources/user/${userId}`,
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   */
  public update(userId: number, body?: UserStore): Observable<IUser> {
    return this.http.put<IUser>(
      `${this.basePath}/v1/resources/user/${userId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * user
   * @param body
   */
  public store(body?: UserStore): Observable<IUser> {
    return this.http.post<IUser>(
      `${this.basePath}/v1/resources/user`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Index
   * List all Users
   * @param page
   * @param user_type
   * @param search
   * @param orderby_column
   * @param orderby_direction
   */
  public index(
    page: number,
    user_type?: string,
    search?: string,
    with_trashed?: number,
    orderby_column: string = 'id',
    orderby_direction: 'DESC' | 'ASC' = 'DESC'
  ): Observable<Content<IUser>> {
    let queryParameters = new HttpParams({
      fromObject: {
        page: page,
        ...(user_type != null && { user_type: user_type }),
        ...(with_trashed != null && { with_trashed: with_trashed }),
        ...(search != null && { search: search }),
        ...(orderby_column != null && { orderby_column: orderby_column }),
        ...(orderby_direction != null && {
          orderby_direction: orderby_direction,
        }),
      },
    });

    return this.http.get<Content<IUser>>(`${this.basePath}/v1/resources/user`, {
      params: queryParameters,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  /**
   * View
   * @param userId
   */
  public view(userId: number): Observable<IUser> {
    return this.http.get<IUser>(
      `${this.basePath}/v1/resources/user/${userId}`,
      this.httpOptions
    );
  }

  /**
   * View
   * @param userId
   */
  public viewUsergroup(groupId: number): Observable<IRole[]> {
    return this.http.get<IRole[]>(
      `${this.basePath}/v1/group/${groupId}/list_users`,
      this.httpOptions
    );
  }
}
