<div
  class="flex items-center {{ classNames }}"
  appClickOutside
  (clickOutside)="clickedOutside()">
  <div class="relative w-full">
    <app-form-field
      [label]="name"
      class="w-full md:w-1/3-with-gap"
      classNames="relative"
      (click)="openfilterSelect()">
      <div *ngIf="!pickValue" class="h-14 relative w-full">
        <input
          class="block w-11/12
        {{ input_cls.common }} cursor-pointer"
          type="text"
          [name]="name"
          [placeholder]="placeholder"
          readonly />
        <img
          appImg
          src="assets/svg/arrow-bottom.svg"
          class="h-6 w-6 absolute right-4 blank-image cursor-pointer top-4 {{
            isOpen ? 'rotate-180' : ''
          }}"
          alt="arrow-bottom" />
      </div>

      <div *ngIf="pickValue" class="h-14 relative w-full">
        <input
          class="block w-11/12
        {{ input_cls.common }} cursor-pointer"
          [value]="pickValue && pickValue!.value"
          type="text"
          [name]="name"
          [placeholder]="placeholder"
          readonly />
        <img
          appImg
          src="assets/svg/arrow-bottom.svg"
          class="h-6 w-6 absolute right-4 blank-image cursor-pointer top-4 {{
            isOpen ? 'rotate-180' : ''
          }}"
          alt="arrow-bottom" />
      </div>
    </app-form-field>
    <ul
      *ngIf="isOpen"
      class="absolute w-full max-h-[200px] overflow-y-scroll bg-light-gray rounded-lg shadow-inner-blue-sm overflow-auto mt-1 z-dropdown">
      <app-option
        *ngFor="let option of options"
        [InCOption]="option"
        [isSelected]="option.label === pickValue?.label"
        (selectEvent)="selectedOption($event)"
        class="w-full"
        classNames="w-full h-12">
        {{ option.value }}
      </app-option>
    </ul>
  </div>
</div>
