import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBuyedpackage } from '../../models/Buyedpackage';
import { Content } from '../../models/Common';

@Injectable()
export class UseCaseUserService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({}),
  };

  constructor(private http: HttpClient) {}

  public countUsers(): Observable<any> {
    return this.http.get<any>(`${this.basePath}/v1/user/count_users`);
  }
  /**
   * my_treatments
   * @param page the page number
   * @param order the datas order (ASC || DESC)
   */
  public myTreatments(
    page?: number,
    order?: string | 'ASC' | 'DESC'
  ): Observable<Content<IBuyedpackage>> {
    let queryParameters = new HttpParams({
      fromObject: {
        ...(order != null && { order: order }),
        ...(page != null && { page: page }),
      },
    });
    return this.http.get<Content<IBuyedpackage>>(
      `${this.basePath}/v1/user/my_treatments`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  public blockUnblock(id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/user/block/${id}`,
      {},
      this.httpOptions
    );
  }
}
