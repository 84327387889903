import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AiService } from 'src/app/core/rest-services/ai.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
})
export class AudioRecorderComponent implements OnInit {
  @Input() streamAudioWhithDeepgram = false;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  mediaRecorder!: MediaRecorder;
  isReady = false;
  recordingStart = false;
  language!: string;
  isLoading = false;
  private audioChunks: Blob[] = [];

  constructor(
    private services: ServicesService,
    private aiService: AiService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.language = this.services.sessionService.getLanguage();
    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      if (!MediaRecorder.isTypeSupported('audio/webm'))
        return alert('Browser not supported');

      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        if (this.streamAudioWhithDeepgram) {
          this.speechToText(audioBlob);
        } else {
          this.triggerEvent.emit(audioBlob);
          this.recordingStart = false;
        }

        this.audioChunks = [];
      };
    });
  }

  speechToText(audioBlob: Blob): void {
    this.isLoading = true;
    this.aiService.speechToTextDeepGram(audioBlob).subscribe({
      next: (transcription: any) => {
        console.log('hey');
        this.triggerEvent.emit(transcription.text);
      },
      error: () => {
        this.notificationService.danger(
          this.translateService.instant('errors.generic')
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  startRecording(): void {
    if (!this.recordingStart) {
      this.mediaRecorder && this.mediaRecorder.start(1000);
      this.recordingStart = true;
    } else {
      this.recordingStart = false;
      this.stopRecording();
    }
  }

  stopRecording(): void {
    this.mediaRecorder && this.mediaRecorder.stop();
  }
}
