import { IHealthcarepackage } from 'src/app/core/models/Healthcarepackage';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  GroupType,
  PERIOD,
  TransactionTarget,
  TransactionType,
} from 'src/app/core/enums/enums';
import { Content, Currency } from 'src/app/core/models/Common';
import { Wallet } from 'src/app/core/models/Group';
import { ITransaction } from 'src/app/core/models/Transaction';
import { IRole, IUser } from 'src/app/core/models/User';
import {
  CurrencyService,
  UseCaseGroupService,
  UseCaseHcpService,
} from 'src/app/core/rest-services/rest-services';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { toHealthCredit, toMoney } from 'src/app/core/utils/misc';
import { OptionObject, PaginationElement } from 'src/app/core/utils/type';
import { TopUpComponent } from 'src/app/shared-components/top-up/top-up.component';
import { DiscountComponent } from 'src/app/shared-components/discount/discount.component';
import { makeCurrencytoShow } from 'src/app/core/utils/functions';
import { CustomRangeCalendarComponent } from '../custom-range-calendar/custom-range-calendar.component';
import { DateRange } from '@angular/material/datepicker';
import { TextLanguagesPipe } from 'src/app/core/pipes/text-languages/text-languages.pipe';
import * as moment from 'moment';
import { RoleService } from 'src/app/core/services/role.service';
import { ImageProfil } from 'src/app/core/models/ImageProfil';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit, OnDestroy {
  @Input() incomingGroupId!: number;
  page = 1;
  pagination: PaginationElement = { page: 1, totalPages: 1 };
  optionPeriodDropdown!: OptionObject[];
  loadingThrottle = 150;
  translations: any = {
    all: '',
    thisDay: '',
    thisWeek: '',
    thisMonth: '',
    thisYear: '',
    custom: '',
  };
  translationKeys: string[] = [
    'admin-fields.all-campaign',
    'admin-fields.day',
    'admin-fields.week',
    'admin-fields.month',
    'admin-fields.year',
    'admin-fields.custom-period',
  ];
  isTransitioning = false;
  wallet!: Wallet;
  currency!: Currency;
  language = '';
  transactions: ITransaction[] = [];
  currencies: Currency[] = [];
  avatar!: ImageProfil;
  transactionType = TransactionType;
  transactionTarget = TransactionTarget;
  $languageChange!: Subscription;
  $usedCredits!: Subscription;
  $userUpdate!: Subscription;
  filterPickedValue?: OptionObject;
  startDate!: Date;
  endDate!: Date;
  period: 'year' | 'month' | 'day' | 'all' | 'sevent_day' | 'custom' = 'all';
  startDateToDisplay = '';
  endDateToDisplay = '';
  textLanguagesPipe: TextLanguagesPipe;
  groupId!: number;
  groupType = GroupType;
  curentRole!: IRole;
  _PERIOD = PERIOD;
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { caller: string },
    public dialogRef: MatDialogRef<TransactionComponent>,
    private translateService: TranslateService,
    private usecaseGroupService: UseCaseGroupService,
    private sessionService: SessionService,
    private services: ServicesService,
    private currencyService: CurrencyService,
    private changeDetectorRef: ChangeDetectorRef,
    private roleService: RoleService,
    private useCaseHcpService: UseCaseHcpService
  ) {
    this.curentRole = this.services.sessionService.getCurrentRole();
    this.textLanguagesPipe = new TextLanguagesPipe(this.changeDetectorRef);
  }

  ngOnDestroy(): void {
    if (this.$languageChange) this.$languageChange.unsubscribe();
    if (this.$usedCredits) this.$usedCredits.unsubscribe();
    if (this.$userUpdate) this.$userUpdate.unsubscribe();
  }

  ngOnInit(): void {
    if (this.incomingGroupId) this.groupId = this.incomingGroupId;
    else if (this.data?.caller !== '_HCP')
      this.groupId = this.incomingGroupId
        ? this.incomingGroupId
        : this.sessionService.getCurrentGroupId();
    else this.groupId = this.roleService.getCurrentHcpId()!;
    this.listenForLanguageChangeEvents();
    this.getTranslations();
    this.currency = this.sessionService.getCurrencie();
    this.language = this.translateService.currentLang;
    this.fetchCurrencies();
    this.fetchWallet();
    this.$usedCredits = this.services.eventService.subscribe(
      'healthcredit:used',
      () => {
        this.transactions = [];
        this.fetchWallet();
        this.fetchTransaction();
      }
    );
    this.$userUpdate = this.services.eventService.subscribe(
      'user:update',
      (user: IUser) => {
        this.currency = this.sessionService.getCurrencie();
      }
    );
  }

  fetchWallet(): void {
    const groupWalletService =
      this.data.caller !== '_HCP'
        ? this.usecaseGroupService
        : this.useCaseHcpService;

    this.isTransitioning = true;
    groupWalletService.wallet(this.groupId).subscribe({
      next: (res: Wallet) => {
        this.wallet = res;
      },
      error: () => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  fetchCurrencies(): void {
    this.isTransitioning = true;
    this.currencyService.index().subscribe({
      next: (res: Currency[]) => {
        this.currencies = res;
      },
      error: () => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  fetchTransaction(): void {
    const groupTransactionService =
      this.data.caller !== '_HCP'
        ? this.usecaseGroupService
        : this.useCaseHcpService;

    this.isLoading = true;
    groupTransactionService
      .list_transactions(
        this.groupId,
        this.period,
        this.period === PERIOD.CUSTOM
          ? moment(this.startDate).format('YYYY-MM-DD')
          : undefined,
        this.period === PERIOD.CUSTOM
          ? moment(this.endDate).format('YYYY-MM-DD')
          : undefined,
        this.page
      )
      .subscribe({
        next: (res: Content<ITransaction>) => {
          this.transactions = [...this.transactions, ...res.data];
          this.pagination = {
            page: this.page,
            totalPages: res.last_page,
            total: res.total,
            perPage: res.per_page,
            form: res.from,
            to: res.to,
          };
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  onScroll(): void {
    this.page++;
    if (this.page <= this.pagination.totalPages) {
      this.fetchTransaction();
    }
  }

  openTopUpModal(): void {
    this.services.modalService.openModal(TopUpComponent, {
      width: '900px',
      height: 'auto',
      data: {
        currencies: this.currencies,
      },
    });
  }

  toLocaleDateTime(date: string): string {
    return toWordLocaleDateTime(new Date(date), this.language);
  }

  getFilter(): void {
    if (this.period === PERIOD.CUSTOM) {
      this.optionPeriodDropdown = [
        {
          label: this.translations.custom,
          value: PERIOD.CUSTOM,
        },
        {
          label: this.translations.thisWeek,
          value: PERIOD.WEEK,
        },
        {
          label: this.translations.thisMonth,
          value: PERIOD.MONTH,
        },
        {
          label: this.translations.thisYear,
          value: PERIOD.YEAR,
        },
        {
          label: this.translations.all,
          value: 'all',
        },
      ];
    } else {
      this.optionPeriodDropdown = [
        {
          label: this.translations.all,
          value: 'all',
        },
        {
          label: this.translations.thisWeek,
          value: PERIOD.WEEK,
        },
        {
          label: this.translations.thisMonth,
          value: PERIOD.MONTH,
        },
        {
          label: this.translations.thisYear,
          value: PERIOD.YEAR,
        },
        {
          label: this.translations.custom,
          value: PERIOD.CUSTOM,
        },
      ];
    }
  }

  handleCustomPeriod(): void {
    this.services.modalService
      .openModal(CustomRangeCalendarComponent, {
        width: '500px',
        height: 'auto',
        data: {
          incomingDateRange: new DateRange<Date>(this.startDate, this.endDate),
        },
      })
      .subscribe((range: DateRange<Date>) => {
        if (range) {
          this.period = PERIOD.CUSTOM;
          this.filterPickedValue = {
            label: this.translations.custom,
            value: PERIOD.CUSTOM,
          };
          this.getFilter();
          this.startDate = new Date(moment(range.start).format('MM/DD/YYYY'));
          this.endDate = new Date(moment(range.end).format('MM/DD/YYYY'));
          this.startDateToDisplay = moment(range.start).format('MM/DD/YYYY');
          this.endDateToDisplay = moment(range.end).format('MM/DD/YYYY');
          this.transactions = [];
          this.page = 1;
          this.fetchTransaction();
        }
      });
  }

  handlePeriodDropdownEvent(period: any): void {
    if (period !== 'open') {
      if (period.value === 'all') {
        this.period = 'all';
        this.transactions = [];
        this.page = 1;
        this.fetchTransaction();
        return;
      }
      this.period = period.value as PERIOD;

      if (this.period === PERIOD.CUSTOM) {
        this.services.modalService
          .openModal(CustomRangeCalendarComponent, {
            width: '500px',
            height: 'auto',
            data: {
              incomingDateRange: new DateRange<Date>(
                this.startDate,
                this.endDate
              ),
            },
          })
          .subscribe((range: DateRange<Date>) => {
            if (range) {
              this.startDate = new Date(
                moment(range.start).format('MM/DD/YYYY')
              );
              this.endDate = new Date(moment(range.end).format('MM/DD/YYYY'));
              this.startDateToDisplay = moment(range.start).format(
                'MM/DD/YYYY'
              );
              this.endDateToDisplay = moment(range.end).format('MM/DD/YYYY');
              this.transactions = [];
              this.page = 1;
              this.fetchTransaction();
            }
          });
      } else {
        this.getFilter();
        const rangePeriod: {
          startDate: Date;
          endDate: Date;
        } = this.services.utilsService.giveRangeOfPeriod(this.period);
        this.startDate = new Date(
          moment(rangePeriod.startDate).format('MM/DD/YYYY')
        );
        this.endDate = new Date(
          moment(rangePeriod.endDate).format('MM/DD/YYYY')
        );
        this.startDateToDisplay = moment(rangePeriod.startDate).format(
          'MM/DD/YYYY'
        );
        this.endDateToDisplay = moment(rangePeriod.endDate).format(
          'MM/DD/YYYY'
        );
        this.transactions = [];
        this.page = 1;
        this.fetchTransaction();
      }
    }
  }

  getTranslations(): void {
    this.translateService.get(this.translationKeys).subscribe(translations => {
      this.translations.all = translations['admin-fields.all-campaign'];
      this.translations.thisDay = translations['admin-fields.day'];
      this.translations.thisWeek = translations['admin-fields.week'];
      this.translations.thisMonth = translations['admin-fields.month'];
      this.translations.thisYear = translations['admin-fields.year'];
      this.translations.custom = translations['admin-fields.custom-period'];
      this.getFilter();
    });
  }

  public listenForLanguageChangeEvents(): void {
    this.services.eventService.subscribe('change:language', () => {
      this.getTranslations();
    });
  }

  openDiscountModal(): void {
    this.services.modalService.openModal(DiscountComponent, {
      width: '500px',
      height: 'auto',
    });
  }

  convertXafXof(currency: string) {
    return makeCurrencytoShow(currency);
  }

  _toHealthCredit(amount: number, rate: number): number {
    return toHealthCredit(amount, rate);
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency ? this.currency.rate : 0);
  }
}
