import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { Note } from 'src/app/core/models/Note';
import {
  BuyedPackageService,
  CurrencyService,
  FileService,
  UseCaseBuyedPackageService,
  UseCaseHcpService,
  UsecasePdfService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ServicesService } from 'src/app/core/services/services.service';
import {
  shortenFileName,
  shortenText,
  toHealthCredit,
  toHealthCreditCeil,
  toMoney,
} from 'src/app/core/utils/misc';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Currency,
  FileResponse,
  IHealthcarepackage,
  IRole,
} from 'src/app/core/models/models';
import { INPUT_CLASSES, SPECIALITIES } from 'src/app/core/utils/constant';
import { HCPInvoiceType } from 'src/app/core/enums/enums';
import { OCRService } from 'src/app/core/rest-services/usecases/ocr.service';
import { ILine, IOcrModelResponse, OrcStore } from 'src/app/core/models/Ocr';
import { OptionObject } from 'src/app/core/utils/type';
import { TextLanguagesPipe } from 'src/app/core/pipes/text-languages/text-languages.pipe';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { BottomSheetService } from 'src/app/core/services/bottom-sheet.service';
import { AppUsageGuideComponent } from 'src/app/shared-components/app-usage-guide/app-usage-guide.component';

enum CHECKOUTSTEPKEY {
  FINAL_INVOICE = 'FINAL_INVOICE',
  INVOICE_AMOUNT = 'INVOICE_AMOUNT',
  CREATE_INVOICE = 'CREATE_INVOICE',
  CONFIRMATION = 'CONFIRMATION',
}

enum PATIENTCHECKOUTMODE {
  UPLOAD_INVOICE = 'UPLOAD_INVOICE',
  CREATE_INVOICE = 'CREATE_INVOICE',
  NONE = 'NONE',
}

export class IMLine {
  name!: string;
  amount!: number;
  doctor_name!: string;
  isEditionMode!: boolean;
  speciality?: string;

  constructor(
    name: string,
    amount: number,
    doctor_name: string,
    isEditionMode: boolean,
    speciality?: string
  ) {
    this.name = name;
    this.amount = amount;
    this.doctor_name = doctor_name;
    this.isEditionMode = isEditionMode;
    this.speciality = speciality;
  }

  get canSave(): boolean {
    if (this.name && this.name.length > 0 && this.amount && !isNaN(this.amount))
      return true;

    return false;
  }
}

@Component({
  selector: 'app-patient-check-out-modal',
  templateUrl: './patient-check-out-modal.component.html',
  styleUrls: ['./patient-check-out-modal.component.scss'],
})
export class PatientCheckOutModalComponent implements OnInit {
  @ViewChild('stepper') private stepper!: MatStepper;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() isLoading = false;
  @Input() _buyedPackage!: IBuyedpackage;
  buyedPackage!: IBuyedpackage;
  input_cls = INPUT_CLASSES;
  finalBill = 0;
  isCheckoutSuccess = false;
  notes!: Note[];
  page = 1;
  pagination: {
    page: number;
    totalPages: number;
  } = { page: 1, totalPages: 1 };
  patientName!: string;
  isEditable = true;
  language = 'fr';
  breackpointMatche = false;
  tutorialData!: any;
  fileName?: string;
  ocrFileName?: string;
  file?: File;
  currentStepKey: CHECKOUTSTEPKEY = CHECKOUTSTEPKEY.FINAL_INVOICE;
  patientCheckoutMode: 'UPLOAD_INVOICE' | 'CREATE_INVOICE' | 'NONE' =
    'UPLOAD_INVOICE';
  currentStepText = '';
  lastStep = 2;
  currentStep = 1;
  PATIENTCHECKOUTMODE = PATIENTCHECKOUTMODE;
  currentStepIcon = 'patient-data.svg';
  currrentTabIndex = 0;
  canBack = false;
  stepperOrientation: Observable<StepperOrientation>;
  uploadPath!: string;
  imagePreview = false;
  pdfPreview = false;
  isExtractingDatas = false;
  billAmount!: number;
  currency!: Currency;
  hcpInvoiceType = HCPInvoiceType;
  currencyCHF!: Currency;
  isAddLineMode = false;
  isEditingLineMode: boolean[] = [];
  currentRole: IRole;
  lines: { libelle: string; price: number }[] = [];
  currentAmount = 0;
  currentLineAmount = 0;
  isRemoveLine = false;
  healthPackages: IHealthcarepackage[] = [];
  healthPackagesBackup: IHealthcarepackage[] = [];
  currentLanguage = 'en';
  selectedHealthcareModules: IHealthcarepackage[] = [];
  price = 0;
  isCreatingInvoice = false;
  isInCheckout = false;
  isStoringFile = false;
  invoiceFiles!: any[];
  medicalSpecialitiesLines!: IMLine[];
  withOldWay = false;
  extractionResponse!: IOcrModelResponse;
  speciaityDropdown: OptionObject[] = [];
  validLinesAmount!: boolean;
  totalAmount!: number;
  ocrFile?: File;
  validPriceAmount = true;
  textLanguagesPipe: TextLanguagesPipe;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      state: IBuyedpackage;
    },
    public dialogRef: MatDialogRef<PatientCheckOutModalComponent>,
    public services: ServicesService,
    private buyedPackageService: BuyedPackageService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private useCaseBuyedPackageService: UseCaseBuyedPackageService,
    private breakpointObserver: BreakpointObserver,
    private customNavigationService: CustomNavigationService,
    public sanitizer: DomSanitizer,
    private fileService: FileService,
    private changeDetectorRef: ChangeDetectorRef,
    private currencyService: CurrencyService,
    private pdfService: UsecasePdfService,
    private usecaseHcp: UseCaseHcpService,
    private ocrService: OCRService,
    private bottomSheetService: BottomSheetService,
    public breakpointMatcherService: BreakpointMatcherService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 815px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    this.currentRole = this.services.sessionService.getCurrentRole();
    this.textLanguagesPipe = new TextLanguagesPipe(this.changeDetectorRef);
  }

  ngOnInit(): void {
    this.currency = this.services.sessionService.getCurrencie();
    this.currentLanguage = this.translateService.currentLang;
    this.getCHFCurrency();
    this._buyedPackage = this.data.state;
    this.getBuyedPackage(this._buyedPackage.id);
    this.fetchHealthPackage();
    this.getSpecialities();
    this.patientName = this._buyedPackage.patient!.adresse
      ? this._buyedPackage.patient!.adresse!.first_name +
          ' ' +
          this._buyedPackage.patient!.adresse &&
        this._buyedPackage.patient!.adresse!.last_name
      : '';
    this.setcurrentStepText();

    this.breakpointMatcherService
      .initObserver('md')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
        } else {
          this.breackpointMatche = false;
        }
      });

    this.tutorialData = this.getTutorialData();
  }

  handleFileUploadChanges(event: any): void {
    const file = event.target.files?.item(0);
    this.isExtractingDatas = true;
    this.ocrService.gptExtractTextFromPDF(file).subscribe({
      next: (res: IOcrModelResponse) => {
        this.patientCheckoutMode = 'UPLOAD_INVOICE';
        if (res.responses) {
          this.extractionResponse = res;
          this.ocrFile = file;
          this.ocrFileName = shortenFileName(file.name);
          this.initDisplayableMedicalLines(res.responses.lines);
          this.currentAmount = res.responses.total_amount;
        } else {
          this.withOldWay = true;
          this.handleChange(event);
        }
        this.next();
      },
      error: () => {
        this.isExtractingDatas = false;
        this.withOldWay = true;
        this.handleChange(event);
      },
      complete: () => {
        this.isExtractingDatas = false;
      },
    });
  }

  initDisplayableMedicalLines(line: ILine[]): void {
    this.medicalSpecialitiesLines = [];
    line.forEach(l => {
      const amount =
        l.amount && l.amount >= this._toMoney(1)
          ? this._toMoney(toHealthCredit(l.amount, this.currency.rate))
          : this._toMoney(1);
      this.currentLineAmount += amount;

      this.medicalSpecialitiesLines.push(
        new IMLine(l.name, amount, l.doctor_name, false)
      );
    });

    this.validSumLineAmount();
  }

  toggleLineEdition(item: IMLine): void {
    item.isEditionMode = !item.isEditionMode;
    if (!item.isEditionMode) {
      this.isAddLineMode = false;
      this.isEditingLineMode.pop();
    } else {
      this.isEditingLineMode.push(item.isEditionMode);
    }
  }

  deleteItem(index: number): void {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.medicalSpecialitiesLines.splice(index, 1);
          this.validSumLineAmount();
        }
      });
    this.removeDeleteBtnFocus();
  }

  getSpecialities(): void {
    [...SPECIALITIES].forEach(sp => {
      this.speciaityDropdown.push({
        label: this.translateService.instant('common.' + sp.key),
        value: sp.key,
      });
    });
  }

  handleSpecialityDropdownEvent(event: any, item: IMLine) {
    if (event.value && event.value !== 'all') {
      item.speciality = event.value;
    }
  }

  removeDeleteBtnFocus() {
    const button = document.getElementById('deleteBtn');
    if (button) button.blur();
  }

  getCHFCurrency() {
    this.currencyService.view(1).subscribe({
      next: (res: Currency) => {
        this.currencyCHF = res;
      },
    });
  }

  public get validAmount(): number {
    return this._toMoney(this._buyedPackage.health_credit_amount);
  }

  public get validAmountCreateInv(): number {
    return (
      this._toMoney(this.data.state.health_credit_amount) - this.currentAmount
    );
  }

  public get fileAmount(): number {
    return this._toMoney(this._buyedPackage?.file_amount ?? 0);
  }

  getBuyedPackage(id: number) {
    this.isLoading = true;
    this.buyedPackageService.view(id).subscribe({
      next: (res: IBuyedpackage) => {
        this.buyedPackage = res;
        this.setFileInfos();
      },
      error: () => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  deleteDocument(file: any) {
    this.isInCheckout = true;
    this.fileService.delete(file.id).subscribe({
      next: (res: any) => {
        this.notificationService.success(
          this.translateService.instant('forms.success-delete-document')
        );
        this.currentAmount = 0;
        this.getBuyedPackage(this.buyedPackage.id);
      },
      error: error => {
        this.isInCheckout = false;
      },
      complete: () => {
        this.isInCheckout = false;
      },
    });
  }

  fetchHealthPackage() {
    this.isLoading = true;
    this.usecaseHcp
      .list_healthcarepackages(this.data.state.healthcareprovider_id)
      .subscribe({
        next: res => {
          this.healthPackages = res.healthcarepackages;
          this.healthPackagesBackup = res.healthcarepackages;
          this.isLoading = false;
        },
        error: err => {
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  public get existBill(): boolean {
    let res = false;
    this.buyedPackage?.files.forEach(file => {
      if (file.type === HCPInvoiceType.FINAL_INVOICE) {
        res = true;
      }
    });
    return res;
  }

  checkout(): void {
    this.isInCheckout = true;
    this.useCaseBuyedPackageService.checkout(this._buyedPackage.id).subscribe({
      next: (res: any) => {
        this.isCheckoutSuccess = true;
        this.isEditable = false;
        this.notificationService.success(
          this.translateService.instant('hcp-home.patient-checkout-Successful')
        );

        this.services.eventService.publish('patient:checkout');
        this.services.eventService.publish('hcp-patient-count:refresh');
        this.closeModal();
        this.customNavigationService.goTo('/hcp/patient-treated');
      },
      error: error => {
        this.isInCheckout = false;
        this.notificationService.danger(
          this.translateService.instant(error.translate)
        );
      },
      complete: () => {
        this.isInCheckout = false;
      },
    });
  }

  saveInvoiceV2() {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
        data: {
          message: this.translateService.instant(
            'hcp-home.checkout-infos-message',
            {
              name: this.patientName,
            }
          ),
        },
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.isStoringFile = true;
          const ocrStoreData = this.initExpectedLines();
          this.fileService
            .store(
              this.ocrFile,
              undefined,
              this.buyedPackage.id,
              'buyedpackage',
              this.hcpInvoiceType.FINAL_INVOICE,
              '{"amount":' +
                ocrStoreData.hc_amount +
                ',"name":"' +
                this.ocrFileName +
                '","amount_chf":' +
                toMoney(ocrStoreData.hc_amount, this.currencyCHF.rate) * 100 +
                ',"amount_client":' +
                this._toMoney(ocrStoreData.hc_amount) * 100 +
                '}'
            )
            .subscribe({
              next: (res: FileResponse) => {
                this.checkoutV2(ocrStoreData);
              },
              error: error => {
                this.notificationService.danger(
                  this.translateService.instant(error.translate)
                );
                this.isStoringFile = false;
              },
              complete: () => {
                this.isStoringFile = false;
              },
            });
        }
      });
  }

  checkoutV2(data: OrcStore): void {
    this.isInCheckout = true;
    this.useCaseBuyedPackageService
      .checkoutV2(data, this._buyedPackage.id)
      .subscribe({
        next: () => {
          this.isCheckoutSuccess = true;
          this.isEditable = false;
          this.notificationService.success(
            this.translateService.instant(
              'hcp-home.patient-checkout-Successful'
            )
          );

          this.services.eventService.publish('patient:checkout');
          this.services.eventService.publish('hcp-patient-count:refresh');
          this.closeModal();
          this.customNavigationService.goTo('/hcp/patient-treated');
        },
        error: error => {
          this.isInCheckout = false;
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
        },
        complete: () => {
          this.isInCheckout = false;
        },
      });
  }

  saveInvoiceFile(): void {
    this.isStoringFile = true;
    this.fileService
      .store(
        this.file,
        undefined,
        this.buyedPackage.id,
        'buyedpackage',
        this.hcpInvoiceType.FINAL_INVOICE,
        '{"amount":' +
          toHealthCreditCeil(this.billAmount, this.currency.rate) +
          ',"name":"' +
          this.fileName +
          '","amount_chf":' +
          toMoney(
            toHealthCreditCeil(this.billAmount, this.currency.rate),
            this.currencyCHF.rate
          ) *
            100 +
          ',"amount_client":' +
          this.billAmount * 100 +
          '}'
      )
      .subscribe({
        next: (res: FileResponse) => {
          this.notificationService.success(
            this.translateService.instant('forms.success-created-document')
          );
          if (this.patientCheckoutMode === PATIENTCHECKOUTMODE.UPLOAD_INVOICE) {
            this.checkout();
          }
        },
        error: error => {
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
          this.isStoringFile = false;
        },
        complete: () => {
          this.isStoringFile = false;
        },
      });
  }

  createInvoice() {
    const data = {
      lines: this.lines,
      currency: this.currency.id,
      amount: toHealthCreditCeil(this.currentAmount, this.currency.rate),
    };
    this.isCreatingInvoice = true;
    this.pdfService.createPdf(data, this.buyedPackage.id).subscribe({
      next: res => {
        this.buyedPackageService.view(this.buyedPackage.id).subscribe({
          next: (res: IBuyedpackage) => {
            this.buyedPackage = res;
            this.setFileInfos();
            this.next();
          },
          error: () => {
            this.isCreatingInvoice = false;
          },
          complete: () => {
            this.isCreatingInvoice = false;
          },
        });
      },
      error: () => {
        this.isCreatingInvoice = false;
      },
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  openConfirmDialog(): void {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
        data: {
          message: this.translateService.instant(
            'hcp-home.checkout-infos-message',
            {
              name: this.patientName,
            }
          ),
        },
      })
      .subscribe(action => {
        if (action === '_YES') {
          if (this.patientCheckoutMode === PATIENTCHECKOUTMODE.UPLOAD_INVOICE) {
            this.saveInvoiceFile();
          } else {
            this.checkout();
          }
        }
      });
  }

  next(canback = true): any {
    this.canBack = canback;
    this.currrentTabIndex++;
    this.stepper.next();
  }

  back(): void {
    if (this.currentStep === 2) this.patientCheckoutMode = 'UPLOAD_INVOICE';
    this.currrentTabIndex =
      this.currrentTabIndex > 0
        ? this.currrentTabIndex - 1
        : this.currrentTabIndex;
    if (this.currrentTabIndex >= 0) {
      this.stepper.previous();
      this.isAddLineMode = false;
      this.selectedHealthcareModules = [];
      this.healthPackages = this.healthPackagesBackup;
      if (this.currrentTabIndex === 0) this.canBack = false;
    } else {
      this.canBack = false;
    }
    this.setcurrentStepText();
  }

  setCurrentStep(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex + 1;
    if (
      this.currentStep === 2 &&
      this.patientCheckoutMode === PATIENTCHECKOUTMODE.UPLOAD_INVOICE
    )
      this.lastStep = 2;
    this.setcurrentStepText();
  }

  setcurrentStepText(): void {
    if (this.currentStep === 1) {
      this.currentStepText = this.translateService.instant(
        'hcp-home.checkout-final-bill-step'
      );
      this.currentStepKey = CHECKOUTSTEPKEY.FINAL_INVOICE;
      this.currentStepIcon = 'patient-data.svg';
    } else if (
      this.patientCheckoutMode === PATIENTCHECKOUTMODE.UPLOAD_INVOICE
    ) {
      this.currentStepText = this.translateService.instant(
        'hcp-home.checkout-bill-amount-step'
      );
      this.currentStepKey = CHECKOUTSTEPKEY.INVOICE_AMOUNT;
      this.currentStepIcon = 'money.svg';
    } else if (
      this.patientCheckoutMode === PATIENTCHECKOUTMODE.CREATE_INVOICE
    ) {
      this.currentStepText = this.translateService.instant(
        this.currentStep === 2
          ? 'hcp-home.checkout-create-bill-step'
          : 'hcp-home.patient-confirmation-label'
      );
      this.currentStepIcon =
        this.currentStep == 2 ? 'file-edit.svg' : 'confirmation.svg';
      this.currentStepKey =
        this.currentStep == 2
          ? CHECKOUTSTEPKEY.CREATE_INVOICE
          : CHECKOUTSTEPKEY.CONFIRMATION;
    }
  }

  handleChange(evt: any): void {
    this.file = evt.target.files?.item(0);
    this.patientCheckoutMode = 'UPLOAD_INVOICE';
    if (this.file) {
      const fileType = this.file.type;
      const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      const isImage = fileType.startsWith('image/');
      const isPDF = fileType === 'application/pdf';

      this.imagePreview = isImage;
      this.pdfPreview = isPDF;

      // Verify if the file type is allowed
      if (!allowedFileTypes.includes(fileType)) {
        this.notificationService.danger(
          this.translateService.instant('errors.wrong-file-type-hcp')
        );
        return;
      } else {
        this.fileName = shortenFileName(this.file.name);
        const reader = new FileReader();

        reader.onload = () => {
          this.uploadPath = reader.result as string;
          this.next();
        };
        reader.readAsDataURL(evt.target.files?.item(0));
      }
    }
  }

  setFileInfos(): void {
    this.invoiceFiles = this.buyedPackage.files.filter(
      f => f.type === HCPInvoiceType.FINAL_INVOICE
    );
    if (this.invoiceFiles.length > 0) {
      const fileFormat = this.invoiceFiles[0].format.toLowerCase();
      const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      if (imageFormats.includes(fileFormat)) {
        this.imagePreview = true;
        this.pdfPreview = false;
        this.uploadPath = this.services.utilsService.getServerUrl(
          this.invoiceFiles[0].path
        );
        this.fileName = shortenFileName(this.invoiceFiles[0].data.name);
      } else {
        this.pdfPreview = true;
        this.imagePreview = false;
        this.fileName = shortenFileName(this.invoiceFiles[0].data.name);
        this.uploadPath = this.services.utilsService.getServerUrl(
          this.invoiceFiles[0].path
        );
      }
    }
  }

  removeLine(line: { libelle: string; price: number }) {
    this.lines = [...this.lines.filter(l => l.libelle !== line.libelle)];
    this.currentAmount -= line.price;
  }

  isSelected(hcm: IHealthcarepackage): boolean {
    const findModule = this.selectedHealthcareModules.find(
      module => module.id === hcm.id
    );
    return findModule ? true : false;
  }

  toggleHealthModule(event: any, hcm: IHealthcarepackage): void {
    if (
      event.target.getAttribute('class') !== null &&
      event.target.getAttribute('class').indexOf('handle-click') !== -1
    ) {
      if (this.selectedHealthcareModules.length > 0) {
        this.healthPackages = this.healthPackagesBackup;
        this.selectedHealthcareModules = [];
      } else {
        this.selectedHealthcareModules.push(hcm);
        this.healthPackages = this.selectedHealthcareModules;
      }
    }
  }

  resetHealthCareModuleSelection(): void {
    this.healthPackages = this.healthPackagesBackup;
    this.selectedHealthcareModules = [];
    this.isAddLineMode = false;
  }

  addInvoiceLine(): void {
    const line = {
      price: this.price,
      libelle: this.textLanguagesPipe.transform(
        this.selectedHealthcareModules[0].title.languages
      ),
      total: this.price,
    };
    this.currentAmount += line.price;
    this.lines.push(line);
    this.healthPackages = this.healthPackagesBackup;
    this.selectedHealthcareModules = [];
    this.isAddLineMode = false;
    this.price = 0;
  }

  setAddLineMode(): void {
    this.isAddLineMode = true;
    this.validPriceAmount = true;
    this.medicalSpecialitiesLines?.unshift(
      new IMLine(
        '',
        this._toMoney(1),
        '',
        true,
        this.speciaityDropdown.length > 0
          ? this.speciaityDropdown[0].value
          : undefined
      )
    );
  }

  cancelAddingLineMode(): void {
    this.isAddLineMode = false;
    this.medicalSpecialitiesLines.shift();
    this.validSumLineAmount();
    this.validPriceAmount = true;
  }

  initExpectedLines(): OrcStore {
    let sum = 0;
    for (const line of this.medicalSpecialitiesLines) {
      // const amountString = line.amount + ''.replace(/,/g, '');
      // const amount = parseFloat(amountString);
      sum += line.amount;
    }
    this.extractionResponse.responses.lines = [];
    this.extractionResponse.responses.total_amount = sum;
    let totalCreditAmount = 0;
    this.medicalSpecialitiesLines.forEach((line: IMLine) => {
      totalCreditAmount += toHealthCreditCeil(line.amount, this.currency.rate);
      this.extractionResponse.responses.lines.push({
        name: line.name,
        amount: toHealthCreditCeil(line.amount, this.currency.rate),
        doctor_name: line.doctor_name,
        speciality: line.speciality,
      });
    });
    return new OrcStore(this.extractionResponse, totalCreditAmount);
  }

  deleteFinaleBill($event: any): void {
    this.file = undefined;
    this.patientCheckoutMode = PATIENTCHECKOUTMODE.UPLOAD_INVOICE;
    if (!this.existBill && this.currentStep === 2) this.back();
    else if (this.existBill) {
      this.deleteDocument(this.invoiceFiles[0]);
      this.lines = [];
    }
  }

  chooseCreateBill($event: any) {
    this.patientCheckoutMode = PATIENTCHECKOUTMODE.CREATE_INVOICE;
    this.lastStep = 2;
    this.next();
  }

  _toLocaleDateTime(date: string): string {
    return toWordLocaleDateTime(new Date(date), this.language);
  }

  setBillAmount(event: any): void {
    this.billAmount = event.target.value;
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency.rate);
  }

  getCurrencyCode(code: string) {
    return this.services.sessionService.getCurrencyCode(code);
  }

  shortenText(text: string) {
    return shortenText(text);
  }

  validSumLineAmount() {
    let amount = 0;
    this.medicalSpecialitiesLines.forEach((value: IMLine) => {
      const tmpAmount = parseFloat(value.amount + '');
      if (!isNaN(tmpAmount)) amount += tmpAmount;
    });
    this.validLinesAmount =
      amount <= this._toMoney(this.buyedPackage.health_credit_amount) &&
      amount >= this._toMoney(this.buyedPackage.file_amount ?? 2);
    this.totalAmount = amount;
  }

  verifyAmount(event: any) {
    if (event.target.value && event.target.value.trim() !== '') {
      const value = parseFloat(event.target.value);
      if (!isNaN(value)) {
        if (this._toMoney(1) > value) {
          this.validPriceAmount = false;
        } else {
          this.validPriceAmount = true;
          this.validSumLineAmount();
        }
      }
    }
  }

  getTutorialData(): any {
    const tutorialData = {
      assetType: '',
      assets: !this.breackpointMatche
        ? [
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-check-out-1.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-check-out-2.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-check-out-3.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-check-out-4.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/mobile/patient-check-out-5.png',
          ]
        : [
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-check-out-1.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-check-out-2.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-check-out-3.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-check-out-4.png',
            'assets/images/tutorials/HCP/' +
              this.currentLanguage +
              '/patient-check-out-5.png',
          ],
      asset: '',
      title: this.translateService.instant('hcp-common.check-out'),
    };
    console.log(this.language);
    return tutorialData;
  }
}
