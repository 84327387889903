import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { GroupType, Permission } from 'src/app/core/enums/enums';
import { IGroup } from 'src/app/core/models/Group';
import { IRole, IUser } from 'src/app/core/models/User';
import {
  UseCaseRoleService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import {
  DEFAULT_IMAGE_BENEFIT,
  DEFAULT_IMAGE_CORPORATE,
  DEFAULT_IMAGE_HCP,
  DEFAULT_IMAGE_KIMBO,
} from 'src/app/core/utils/constant';
import { OptionObject, PaginationElement } from 'src/app/core/utils/type';
import { CreateEditRolesComponent } from 'src/app/pages/containers/components/create-edit-roles/create-edit-roles.component';
import { RoleSelectionModalComponent } from './role-selection-modal/role-selection-modal.component';
import { Content } from 'src/app/core/models/Common';

@Component({
  selector: 'app-menu-role-select',
  templateUrl: './menu-role-select.component.html',
  styleUrls: ['./menu-role-select.component.scss'],
})
export class MenuRoleSelectComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  isOpen = false;
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() pickValue?: OptionObject;
  @Input() optionWidth?: string;
  @Input() caller: '_CORPORATE' | '_GLOBAL' | '_BENEFIT' = '_GLOBAL';
  @Input() backdrop = false;
  @Input() variant: '_MENU' | '_DROPDOWN' | '_MODAL' = '_MENU';
  @Input() classNames = '';
  _role_selected!: IRole;

  user!: IUser;
  translations = {
    companyRole: '',
    AddGroup: '',
  };
  translationKeys: string[] = [
    'common.company-role',
    'admin-fields.create-group-label',
  ];
  groupType = GroupType;
  $rolechange!: Subscription;
  $createGroup!: Subscription;
  $updateGroup!: Subscription;
  activeLocation!: string | undefined;
  $languageChange!: Subscription;
  $updateUser!: Subscription;
  currentRole!: IRole;
  currentRoleType!: GroupType;
  isRoutingByClick = false;
  _user!: IUser;
  browserRefresh = false;
  $loginEnd?: Subscription;
  $rolePicked!: Subscription;

  constructor(
    public languageService: LanguageService,
    public sessionService: SessionService,
    public navigationService: CustomNavigationService,
    public userService: UserService,
    public services: ServicesService,
    public usecaseRoleService: UseCaseRoleService,
    public router: Router
  ) {}

  ngAfterViewChecked(): void {
    this.isRoutingByClick = false;
  }

  ngOnDestroy(): void {
    if (this.$rolechange) this.$rolechange.unsubscribe();
    if (this.$createGroup) this.$createGroup.unsubscribe();
    if (this.$updateGroup) this.$updateGroup.unsubscribe();
    if (this.$loginEnd) this.$loginEnd.unsubscribe();
    this.$languageChange?.unsubscribe();
    this.$rolePicked?.unsubscribe();
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    this.currentRole = this.sessionService.getCurrentRole();
    if (this.currentRole.id === -1) {
      this.pickValue = {
        label: this.currentRole.group.type,
        value: GroupType.Admin,
        src: 'assets/images/settings.png',
      };
    } else {
      this.setPickValue();
    }
    this.getTranslations();
    this.listenForLanguageEvents();
    if (this.currentRole) {
      this.currentRoleType = this.services.roleService.getCurrentTypeRole();
    }
    this.$rolePicked = this.services.eventService.subscribe(
      'role:picked',
      (role: OptionObject) => {
        this.pickValue = role;
        this.handlerRoleDropdownEvent(this.pickValue);
      }
    );
    this.$rolechange = this.services.eventService.subscribe(
      'change:role',
      (role: IRole) => {
        this.currentRole = this.services.roleService.getCurrentRole();
        this.currentRoleType = this.services.roleService.getCurrentTypeRole();
        this.activeLocation = undefined;
        this.services.eventService.publish('owner:has', this.currentRole);
      }
    );

    this.$createGroup = this.services.eventService.subscribe(
      'group:create',
      (role: IRole) => {
        const createOption: OptionObject = {
          label: role.group.name,
          value: role.id.toString(),
        };
        this.currentRole = role;
        this.currentRoleType = this.services.roleService.getCurrentTypeRole();
        this.setPickValue();

        this.handlerRoleDropdownEvent(createOption);
      }
    );

    this.$updateGroup = this.services.eventService.subscribe(
      'group:update',
      (group: IGroup) => {
        this._user = this.sessionService.getUser();
        const role = this._user.roles!.find(role => role.group_id == group.id)!;
        this.currentRole = role;
        if (this.currentRole.group.id === group.id) {
          this.pickValue!.src = this.services.utilsService.getServerUrl(
            this.currentRole.group.image_profil?.path!
          );
        }
        this.currentRoleType = this.services.roleService.getCurrentTypeRole();
        const createOption: OptionObject = {
          label: role.group.name,
          value: role.id.toString(),
        };
        this.setPickValue();

        this.handlerRoleDropdownEvent(createOption);
      }
    );

    this.$loginEnd = this.services.eventService.subscribe(
      'login:end',
      (user: IUser) => {
        this.user = user;
      }
    );
  }

  setPickValue(): void {
    this.pickValue = {
      label: this.currentRole.group.name,
      value: this.currentRole.id.toString(),
      src:
        this.currentRole.group.image_profil &&
        this.currentRole.group.image_profil.path !==
          'public/general/unknow.png' &&
        this.currentRole.group.image_profil.path !== 'storage/general/group.png'
          ? this.services.utilsService.getServerUrl(
              this.currentRole.group.image_profil.path
            )
          : this.currentRole.group.type === GroupType.Hcp
          ? DEFAULT_IMAGE_HCP
          : this.currentRole.group.type === GroupType.Companie
          ? DEFAULT_IMAGE_CORPORATE
          : this.currentRole.group.type === GroupType.Benefit
          ? DEFAULT_IMAGE_BENEFIT
          : DEFAULT_IMAGE_KIMBO,
    };
  }

  handlerRoleDropdownEvent(selectedOption: any): void {
    this.isRoutingByClick = true;
    this.triggerEvent.emit(selectedOption);
    if (!selectedOption?.action && selectedOption?.value !== '_ADD_GROUP') {
      const user = this.sessionService.getUser();
      if (selectedOption?.label && user.roles) {
        let _role_selected = user.roles.find(
          role => role.id == Number(selectedOption.value)
        );
        if (selectedOption.value == GroupType.Admin) {
          _role_selected = this.sessionService.getRole(GroupType.Admin);
        }
        if (_role_selected != undefined) {
          if (!this.activeLocation)
            this.services.roleService.changeRole(_role_selected);
          else
            this.services.roleService.changeRole(
              _role_selected,
              this.activeLocation
            );
          this.currentRole = this.services.roleService.getCurrentRole();
          this.currentRoleType = this.services.roleService.getCurrentTypeRole();
        }
      }
    } else {
      if (
        selectedOption.data &&
        (selectedOption.action === '_EDIT_GROUP' ||
          selectedOption.action === '_CREATE_GROUP' ||
          selectedOption.value === '_ADD_GROUP')
      ) {
        this.services.modalService.openModal(CreateEditRolesComponent, {
          height: 'auto',
          width: '800px',
          data: {
            action: selectedOption.action,
            state: selectedOption.data,
            caller: '_OTHER',
          },
        });
      }
    }

    this.triggerEvent.emit('_HIDE');
  }

  isGroupOwer(option: OptionObject): boolean {
    this.user = this.sessionService.getUser();
    if (
      this._role_selected &&
      this._role_selected?.permissions?.length === 0 &&
      this.user.admin_type &&
      this.user.admin_type === 'admin'
    )
      return true;
    if (this.user) {
      this._role_selected = this.user.roles!.find(
        role => role.id == Number(option.value)
      )!;
      return this._role_selected?.permissions?.includes(Permission.Owner);
    } else return false;
  }

  selectedOption(option: OptionObject): void {
    if (option.value != '_ADD_GROUP') {
      this.pickValue = option;

      this.handlerRoleDropdownEvent(option);
      this.isOpen = false;
    } else {
      this.handleGroupActions('_CREATE_GROUP', option);
    }
  }

  getRoleName(option: OptionObject): string {
    let role!: IRole;
    if (option.value != 'admin')
      role = this.user.roles!.find(role => role.id == Number(option.value))!;
    return role ? role.group.type : '';
  }

  clickedOutside(): void {
    this.isOpen = false;
  }

  handleGroupActions(
    action: '_CREATE_GROUP' | '_EDIT_GROUP',
    pickValue: OptionObject
  ): void {
    this.handlerRoleDropdownEvent({ action: action, data: pickValue });
    this.isOpen = false;
  }

  openRoleModal(): void {
    this.services.modalService
      .openModal(RoleSelectionModalComponent, {
        width: '400px',
        height: '630px',
        data: {
          selectedRole: this.pickValue,
        },
      })
      .subscribe((selectedRole: OptionObject) => {
        if (selectedRole && selectedRole?.value != '_ADD_GROUP') {
          if (selectedRole.value !== this.pickValue?.value) {
            this.pickValue = selectedRole;
            this.handlerRoleDropdownEvent(selectedRole);
          } else {
            if (this.currentRole.group.type !== this.groupType.Admin)
              this.handleGroupActions('_EDIT_GROUP', this.pickValue!);
          }
        } else if (selectedRole && selectedRole?.value === '_ADD_GROUP') {
          this.handleGroupActions('_CREATE_GROUP', selectedRole);
        }
      });
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.companyRole = translations['common.company-role'];
      this.translations.AddGroup =
        translations['admin-fields.create-group-label'];
    });
  }

  public listenForLanguageEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }

  close(): void {
    this.isOpen = false;
  }
}
