import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  PayCampaign,
  GenericResponse,
  PayHealthcarepackage,
  PayTopUp,
  PayBenefit,
} from '../../models/models';

@Injectable()
export class PaymentService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Pay Campaign
   * Buy multiple healthpackages for a campaign
   * @param body
   */
  public payCampaign(body?: PayCampaign): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      `${this.basePath}/v1/pay/campaign`,
      body,
      this.httpOptions
    );
  }

  /**
   * Pay Healthpackage
   * @param body
   */
  public payHealthPackage(body?: PayHealthcarepackage): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/pay/healthpackages`,
      body,
      this.httpOptions
    );
  }

  /**
   * Pay TopUp
   * @param body
   */
  public payTopUp(body?: PayTopUp): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/pay/topup`,
      body,
      this.httpOptions
    );
  }

  /**
   * Pay TopBenefit
   * @param body
   */
  public payBenefit(body: PayBenefit, group_id: number): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/pay/transfert_credits/${group_id}`,
      body,
      this.httpOptions
    );
  }
}
