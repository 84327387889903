<button class="close-button" color="primary" mat-dialog-close mat-button>
  <mat-icon>close</mat-icon>
</button>
<div class="flex gap-6 justify-between">
  <div
    *ngIf="paymentSuccess"
    class="w-full flex flex-col items-center justify-center my-8 back-confetti">
    <app-image-render
      class="w-48"
      [classNames]="'w-48'"
      img="/assets/svg/winners.svg"></app-image-render>
    <div class="text-xl font-bold mt-6 mb-4">
      {{ 'kimbo-top-up.payment-success-notification-title' | translate }}
    </div>
    <div class="text-lg mt-2 mb-6 w-full md:w-1/2 text-center">
      {{ 'kimbo-top-up.payment-success-notification-content' | translate }}
    </div>
  </div>
  <div
    *ngIf="!paymentSuccess"
    class="hidden sm:block sm:w-6/12 border-r-1 border-gray-200 {{
      this.currentRole.group.type === groupType.Kimbo
        ? 'back-soft'
        : 'back-soft-company'
    }}"></div>
  <div *ngIf="!paymentSuccess" class="w-full sm:w-6/12 sm:pr-4 bg-white">
    <div class="flex items-center justify-start gap-2 mb-6">
      <h5 class="text-lg font-semibold text-navy md:text-xl">
        {{ 'kimbo-top-up.h1' | translate }}
      </h5>
    </div>
    <div>
      <app-input-dropdown
        class="w-full mb-6"
        (triggerEvent)="handleCurrencyDropdownEvent($event)"
        [options]="currencyOptions ? currencyOptions : []"
        [pickValue]="selectedCurrencie"
        [name]="'admin-fields.currency-label' | translate"></app-input-dropdown>

      <app-form-field
        [label]="'kimbo-top-up.amount-label' | translate"
        [classNames]="'mt-8'">
        <div class="w-full flex flex-wrap items-center justify-between">
          <label
            *ngFor="let amount of currencyVariants; let i = index"
            class="items-center inline-flex justify-center h-14 px-4 rounded-lg text-center focus:outline-none cursor-pointer
      text-sm {{
              amountSelected && amountSelected === amount
                ? 'bg-blue text-white font-semibold '
                : 'shadow-inner-blue-sm'
            }} w-1/2-with-gap sm:w-1/3-with-gap sm:mt-0 {{
              i > 1 ? 'mt-2' : ''
            }} {{ i < 3 ? 'sm:mb-4' : '' }}"
            for="topupamount-{{ amount }}"
            tabIndex="0">
            <input
              [checked]="amountSelected && amountSelected === amount"
              type="radio"
              class="hidden"
              id="topupamount-{{ amount }}"
              (change)="handleAmountChange($event)" />
            <span>{{
              'common.money-s'
                | translate
                  : {
                      amount: amount,
                      currency: selectedCurrencie!.label.split('::')[1]
                    }
            }}</span>
          </label>
          <span
            class="flex items-center flex-grow mt-2 sm:mt-0 sm:ml-4 w-1/2-with-gap sm:w-2/3-with-gap gap-3">
            {{ 'kimbo-top-up.custom-amount-label' | translate }}
            <input
              class="w-full
        {{ input_cls.common }}"
              type="text"
              (input)="handleCustomAmount($event)" />
          </span>
        </div>
        <div *ngIf="error" class="text-sm text-red block w-full mt-1">
          {{ error }}
        </div>
      </app-form-field>
      <div
        *ngIf="selectedCurrencie && !isWrongAmount"
        class="mt-6 border-t-1 border-gray pt-6">
        <div class="font-bold text-navy">
          {{ 'kimbo-send-credits.total-price-label' | translate }}
        </div>
        <div class="flex items-center justify-between mt-2 text-navy">
          <span class="font-bold text-xl">
            {{
              'common.hc'
                | translate : { amount: _toHealthCredit(amountSelected!) }
            }}
          </span>
        </div>
        <div class="mt-2 mb-3 text-navy">
          {{
            'common.money-s'
              | translate
                : {
                    amount: amountSelected,
                    currency: selectedCurrencie!.label.split('::')[1]
                  }
          }}
        </div>
        <div
          *ngIf="showCreditCard && selectedCurrencie && !isWrongAmount"
          class="flex items-center justify-between py-4 border-b-[1px] border-b-[#7682B729]">
          <div>
            <input
              id="_CARD_NUMBER"
              [checked]="iscrediCard"
              type="radio"
              class="w-4 h-4 p-1 mr-3 accent-corporateblue cursor-pointer"
              (click)="paymentMethodChecked($event)" />
            <label
              for="_CARD_NUMBER"
              class="text-[16px] font-light cursor-pointer text-navy"
              >{{ 'kimbo-send-credits.credit-card-label' | translate }}</label
            >
          </div>
          <div>
            <app-image-render
              classNames="w-24"
              img="/assets/images/visa.png"></app-image-render>
          </div>
        </div>

        <div
          *ngIf="selectedCurrencie && !isWrongAmount && showMobilePaiement"
          class="flex items-center justify-between py-4 border-b-[1px] border-b-[#7682B729]">
          <div>
            <input
              id="_MOBILE_PAYMENT"
              [checked]="ismobile"
              type="radio"
              class="w-4 h-4 p-1 mr-3 accent-corporateblue cursor-pointer"
              (click)="paymentMethodChecked($event)" />
            <label
              for="_MOBILE_PAYMENT"
              class="text-[16px] font-light cursor-pointer text-navy"
              >{{ 'kimbo-send-credits.mobile-label' | translate }}</label
            >
          </div>
          <div class="flex items-center">
            <img
              *ngIf="!isTransitioning"
              class="w-8"
              src="assets/images/mobile.png"
              alt="mobile" />
            <app-spinner
              *ngIf="isTransitioning"
              class="inline-block h-6 w-6 filter-blue"></app-spinner>
          </div>
        </div>

        <div
          *ngIf="selectedCurrencie && !isWrongAmount"
          class="flex items-center justify-between py-4 border-b-[1px] border-b-[#7682B729]">
          <div>
            <input
              id="_BANK"
              [checked]="isbankTransfert"
              type="radio"
              class="w-4 h-4 p-1 mr-3 accent-corporateblue cursor-pointer"
              (click)="paymentMethodChecked($event)" />
            <label
              for="_BANK"
              class="text-[16px] font-light cursor-pointer text-navy"
              >{{
                'kimbo-send-credits.bank-transfert-label' | translate
              }}</label
            >
          </div>
          <div class="flex items-center">
            <img
              class="w-8"
              src="assets/images/bank-transfer_1.png"
              alt="transfer_1" />
          </div>
        </div>
        <p
          *ngIf="
            (iscrediCard || isbankTransfert || ismobile) &&
            selectedCurrencie &&
            !isWrongAmount
          "
          class="mt-6 text-center text-dark-gray"
          [innerHTML]="'kimbo-top-up.submit-clause' | translate"></p>
        <div class="my-8 flex justify-center">
          <button
            *ngIf="
              (iscrediCard || isbankTransfert || ismobile) &&
              selectedCurrencie &&
              !isWrongAmount
            "
            class="w-full h-12"
            mat-raised-button
            color="primary"
            [disabled]="isTransitioning"
            (click)="topUp()"
            type="submit">
            <app-spinner
              *ngIf="isTransitioning"
              class="inline-block h-6 w-6 filter-blue"></app-spinner>
            <span *ngIf="!isTransitioning">{{
              'kimbo-top-up.submit-button' | translate
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
