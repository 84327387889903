import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
})
export class InputDropdownComponent implements OnInit {
  isOpen = false;
  @Input() options: OptionObject[] = [];
  @Input() classNames = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() pickValue?: OptionObject;
  @Input() withLabel = true;
  input_cls = INPUT_CLASSES;

  constructor() {}

  ngOnInit(): void {
    if (
      !this.pickValue &&
      this.placeholder.length === 0 &&
      this.options.length > 0
    ) {
      this.pickValue = this.options[0];
    }
    this.triggerEvent.emit(this.pickValue);
  }

  clickedOutside(): void {
    this.isOpen = false;
  }

  selectedOption(option: OptionObject): void {
    this.pickValue = option;
    this.isOpen = false;
    this.triggerEvent.emit(option);
  }

  openfilterSelect(): void {
    this.isOpen = !this.isOpen;
  }
}
