import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GroupType, Permission } from 'src/app/core/enums/enums';
import { IRole, IUser } from 'src/app/core/models/User';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { SessionService } from 'src/app/core/services/session.service';
import { OptionObject } from 'src/app/core/utils/type';
import { environment } from 'src/environments/environment';
import { FaqComponent } from '../faq/faq.component';
import { ProfileComponent } from '../profile/profile.component';
import { PreLoginInfosComponent } from '../../../../shared-components/login/pre-login-infos/pre-login-infos.component';
import { LoginModalComponent } from '../../../../shared-components/login/login-modal/login-modal.component';
import { CreateEditRolesComponent } from '../create-edit-roles/create-edit-roles.component';
import { RestAuthentificationService } from 'src/app/core/rest-services/rest-services';
import { DiscountComponent } from 'src/app/shared-components/discount/discount.component';

@Component({
  selector: 'app-mobile-drawer',
  templateUrl: './mobile-drawer.component.html',
  styleUrls: ['./mobile-drawer.component.scss'],
})
export class MobileDrawerComponent implements OnInit, OnDestroy {
  filterOptions: OptionObject[] = [];
  params: any = {
    size: 'medium',
    theme: 'blue',
  };
  translations: any = {
    companyRole: '',
    AddGroup: '',
  };
  translationKeys: string[] = [
    'common.company-role',
    'admin-fields.create-group-label',
  ];
  selectedOption!: OptionObject;

  visibilitySubscription?: Subscription;
  isModalProfilOpen = false;
  showRoleDropdown = true;
  currentRole!: IRole;
  currentRoleType!: GroupType;
  _user!: IUser;
  breackpointMatche = false;
  isRoutingByClick = false;
  $rolechange!: Subscription;
  $createGroup!: Subscription;
  $updateGroup!: Subscription;
  activeLocation!: string | undefined;
  $languageChange!: Subscription;
  $updateUser!: Subscription;
  $show!: Subscription;
  badge = false;
  enviromentname = '';
  appVersion = '';
  groupType = GroupType;
  isVisible = false;
  isGuest = false;
  isGroupOwer_ = false;
  user!: IUser;
  dropdownSelectedRole!: OptionObject;

  constructor(
    public breakpointMatcherService: BreakpointMatcherService,
    public languageService: TranslateService,
    public sessionService: SessionService,
    public navigationService: CustomNavigationService,
    public services: ServicesService,
    public authService: RestAuthentificationService,
    public router: Router
  ) {
    this.isGuest = this.sessionService.isGuest();
  }

  ngOnDestroy(): void {
    this.$updateUser && this.$updateUser.unsubscribe();
    this.$rolechange && this.$rolechange.unsubscribe();
    this.$show && this.$show.unsubscribe();
  }

  ngOnInit(): void {
    this.badge = environment.badge;
    this.enviromentname = environment.name;
    this.appVersion = environment.appVersion;
    this.currentRole = this.sessionService.getCurrentRole();
    this._user = this.sessionService.getUser();
    if (this.currentRole) {
      this.currentRoleType = this.services.roleService.getCurrentTypeRole();
    }
    this.$rolechange = this.services.eventService.subscribe(
      'change:role',
      (role: IRole) => {
        this.currentRole = this.services.roleService.getCurrentRole();
        this.currentRoleType = this.services.roleService.getCurrentTypeRole();
      }
    );
    this.$updateUser = this.services.eventService.subscribe(
      'user:update',
      (user: IUser) => {
        this._user = user;
      }
    );
    this.$show = this.services.eventService.subscribe(
      'show:mobiledrawer',
      () => {
        this.isVisible = true;
      }
    );
  }

  openSignUpModal(): void {
    this.isVisible = false;
    this.services.modalService
      .openModal(PreLoginInfosComponent, {
        width: '400px',
        height: 'auto',
        data: {
          caller: '_SIGN_UP',
        },
      })
      .subscribe(res => {
        if (
          res === '_SIGN_IN' ||
          (typeof res === 'object' && res.caller === '_SIGN_IN')
        ) {
          this.services.modalService.openModal(LoginModalComponent, {
            width: '950px',
            height: 'auto',
            data: {
              params: {
                ...(typeof res === 'object' && { identifier: res.state }),
              },
              caller: '_SIGN_IN',
            },
          });
        }
      });
  }

  openProfilDialog(): void {
    if (!this.sessionService.isGuest()) {
      this.isVisible = false;
      this.services.modalService.openModal(ProfileComponent, {
        width: '1300px',
        height: 'auto',
      });
    }
  }

  openSupportModal(): void {
    this.isVisible = false;
    this.services.modalService.openModal(FaqComponent, {
      width: '500px',
      height: 'auto',
    });
    this.hide();
  }

  handleEvent(event: any): void {
    if (event === '_HIDE') this.isVisible = false;
    else {
      this.dropdownSelectedRole = event;
      this.isGroupOwer_ = this.isGroupOwer(event);
    }
  }

  isGroupOwer(option: OptionObject): boolean {
    this.user = this.sessionService.getUser();
    if (
      this.currentRole &&
      this.currentRole?.permissions?.length === 0 &&
      this.user.admin_type &&
      this.user.admin_type === 'admin'
    ) {
      return true;
    } else {
      this.currentRole = this.user.roles!.find(
        role => role.id == Number(option.value)
      )!;
      return this.currentRole?.permissions?.includes(Permission.Owner);
    }
  }

  logout(): void {
    this.authService.logout().subscribe({
      next: (value: any) => {
        this.sessionService.logout();
      },
    });
  }

  hide(): void {
    this.isVisible = false;
  }

  show(): void {
    this.isVisible = true;
  }

  handleGroupActions(action: '_CREATE_GROUP' | '_EDIT_GROUP'): void {
    this.services.modalService.openModal(CreateEditRolesComponent, {
      height: 'auto',
      data: {
        action: '_EDIT_GROUP',
        state: this.dropdownSelectedRole,
        caller: '_OTHER',
      },
    });
  }

  openDiscountModal(): void {
    this.isVisible = false;
    if (this.services.sessionService.isGuest()) {
      this.services.modalService
        .openModal(PreLoginInfosComponent, {
          width: '400px',
          height: 'auto',
          data: {
            caller: '_SIGN_UP',
          },
        })
        .subscribe(res => {
          if (
            res === '_SIGN_IN' ||
            (typeof res === 'object' && res.caller === '_SIGN_IN')
          ) {
            this.services.modalService.openModal(LoginModalComponent, {
              width: '950px',
              height: 'auto',
              data: {
                params: {
                  ...(typeof res === 'object' && { identifier: res.state }),
                },
                caller: '_SIGN_IN',
              },
            });
          }
        });
    } else {
      this.services.modalService
        .openModal(DiscountComponent, {
          width: '500px',
          height: 'auto',
        })
        .subscribe(action => {});
    }
  }

  openLoginModal(caller: string): void {
    this.isVisible = false;
    this.sessionService.openLoginModal(undefined, undefined, caller);
  }
}
