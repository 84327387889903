import { GroupType } from '../enums/enums';
import { IRoleAdmin } from './AdminRole';
import { Bonus, Currency, Data } from './Common';
import { Group, IGroup } from './Group';
import { ImageProfil } from './ImageProfil';

export interface IUser {
  id: number;
  email?: string;
  tel?: string;
  gender?: string;
  timezone: string;
  data: any;
  adresse: any;
  admin_type: string;
  properties: Properties;
  public_properties: PublicProperties;
  user_type: string;
  has_whatsapp: number;
  trashed_at: any;
  created_at: string;
  updated_at: string;
  deleted_at: any;
  stripe_id: any;
  pm_type: any;
  pm_last_four: any;
  trial_ends_at: any;
  currencie_id: number;
  currencie: Currency;
  language_id: number;
  image_profil: ImageProfil;
  create_account_bonus: Bonus;
  roles?: IRole[];
  adminroles?: IRoleAdmin[];
  is_blocked?: boolean;
  two_fa_active?: boolean;
  is_required_twofa?: boolean;
}

interface PublicProperties {
  active: boolean;
}
interface Properties {
  tel: string;
  email: string;
}
export interface GroupeResponse {
  role: IRole;
  group: IGroup;
}

export class RoleStore {
  tel?: string;
  group_id?: number;
  permissions?: string[];
  email?: string;

  constructor(
    tel?: string,
    group_id?: number,
    permissions?: string[],
    email?: string
  ) {
    this.tel = tel;
    this.group_id = group_id;
    this.permissions = permissions;
    this.email = email;
  }
}

export interface IRole {
  id: number;
  user_id: number;
  tel: any;
  permissions: string[];
  group_id: number;
  group: IGroup;
  user?: IUser;
}

export class AdminRole implements IRole {
  id = -1;
  user_id = 1;
  tel = '';
  permissions = ['admin'];
  group_id = -1;
  group: IGroup;

  constructor() {
    this.group = new Group(-1, 'admin', GroupType.Admin);
  }
}

export class DefaultRole implements IRole {
  id = -2;
  user_id = 0;
  tel = '';
  permissions = ['kimbo'];
  group_id = -2;
  group: IGroup;

  constructor() {
    this.group = new Group(-1, 'kimbo', GroupType.Kimbo);
  }
}

export class UserStore {
  email?: string;
  gender?: string;
  tel?: string;
  timezone?: string;
  data?: Data;
  currencie_id?: number;
  language_id?: number;
  has_whatsapp?: number;

  constructor(
    email?: string,
    tel?: string,
    gender?: string,
    timezone?: string,
    data?: Data,
    currencie_id?: number,
    language_id?: number,
    has_whatsapp?: number
  ) {
    this.email = email;
    this.gender = gender;
    this.tel = tel;
    this.timezone = timezone;
    this.data = data;
    this.currencie_id = currencie_id;
    this.language_id = language_id;
    this.has_whatsapp = has_whatsapp;
  }
}

export interface UserCommonResponse {
  user: IUser;
}

export class User {
  id?: number;
  timezone?: string;
  data?: any;
  adresse?: any;
  properties?: Properties;
  admin_type?: string;
  user_type?: string;
  has_whatsapp?: number;
  trashed_at?: any;
  created_at?: string;
  updated_at?: string;
  deleted_at?: any;
  stripe_id?: any;
  pm_type?: any;
  pm_last_four?: any;
  trial_ends_at?: any;
  currencie_id?: number;
  language_id?: number;
  create_account_bonus?: number;
  image_profil?: ImageProfil;
  is_blocked?: boolean;
  two_fa_active?: boolean;
  is_required_twofa?: boolean;

  constructor(
    id?: number,
    timezone?: string,
    data?: any,
    adresse?: any,
    admin_type?: string,
    user_type?: string,
    has_whatsapp?: number,
    trashed_at?: any,
    created_at?: string,
    updated_at?: string,
    deleted_at?: any,
    stripe_id?: any,
    pm_type?: any,
    pm_last_four?: any,
    trial_ends_at?: any,
    currencie_id?: number,
    language_id?: number,
    image_profil?: ImageProfil,
    is_blocked?: boolean,
    two_fa_active?: boolean,
    is_required_twofa?: boolean
  ) {
    this.id = id;
    this.timezone = timezone;
    this.data = data;
    this.adresse = adresse;
    this.admin_type = admin_type;
    this.user_type = user_type;
    this.has_whatsapp = has_whatsapp;
    this.trashed_at = trashed_at;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.stripe_id = stripe_id;
    this.pm_type = pm_type;
    this.pm_last_four = pm_last_four;
    this.trial_ends_at = trial_ends_at;
    this.currencie_id = currencie_id;
    this.language_id = language_id;
    this.image_profil = image_profil;
    this.is_blocked = is_blocked;
    this.two_fa_active = two_fa_active;
    this.is_required_twofa = is_required_twofa;
  }
}

export interface IPublicMember {
  id: number;
  name: string;
  email: string;
  tel: string;
}
