<h2 *ngIf="!incomingGroupId" class="text-navy" mat-dialog-title>
  <span>{{ 'kimbo-layout.hc-wallet-button' | translate }}</span>
</h2>
<button
  *ngIf="!incomingGroupId"
  class="close-button z-notifier"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="dialog close icon">
  <mat-icon>close</mat-icon>
</button>

<ng-container
  *ngIf="incomingGroupId"
  [ngTemplateOutlet]="transaction"></ng-container>

<mat-dialog-content *ngIf="!incomingGroupId">
  <ng-container [ngTemplateOutlet]="transaction"></ng-container>
</mat-dialog-content>

<ng-template #transaction>
  <div
    class="{{ !incomingGroupId ? 'py-8' : '' }} {{
      curentRole.group.type !== groupType.Admin && 'p-6'
    }}  h-[800px] overflow-y-auto"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="150"
    [scrollWindow]="false"
    (scrolled)="onScroll()">
    <div *ngIf="!incomingGroupId" class="md:flex md:items-stretch">
      <div class="flex-grow">
        <div
          class="p-4 rounded-xl bg-kimbo-blue bg-opacity-5 md:p-8 md:rounded-xl">
          <div
            class="flex flex-col items-start justify-between md:flex-row md:items-center">
            <div
              *ngIf="isTransitioning"
              class="w-full flex items-center justify-center h-full filter-blue">
              <app-spinner class="inline-block ml-4 h-8 w-8"></app-spinner>
            </div>
            <div
              *ngIf="!isTransitioning"
              class="w-full md:w-fit flex items-center gap-3 md:mt-0 justify-start relative">
              <i
                class="text-4xl text-kimbo-blue ri-money-dollar-circle-line"></i>

              <div
                class="w-full md:w-fit font-bold text-navy flex flex-col md:items-end justify-center">
                <span
                  class="text-[19px] text-navy font-semibold text-end md:text-start"
                  >{{ 'kimbo-wallet.balance-label' | translate }}</span
                >
                <p *ngIf="wallet" class="text-[20px] text-end md:text-start">
                  {{ _toMoney(wallet.amount) }}
                  {{ currency ? convertXafXof(currency.code) : '' }}
                </p>
                <p *ngIf="wallet" class="text-[11px] text-end md:text-start">
                  {{ wallet.amount }}
                  {{ 'kimbo-home.healthcredit-label' | translate }}
                </p>
              </div>
            </div>
            <div
              *ngIf="
                !isTransitioning &&
                data.caller !== '_CORPORATE' &&
                data.caller !== '_HCP'
              "
              class="w-full flex-col mt-6 justify-between gap-4 md:gap-0 md:w-fit md:mt-0 flex items-center md:justify-end">
              <button
                class="w-full h-12"
                mat-raised-button
                color="primary"
                (click)="openTopUpModal()"
                [disabled]="isTransitioning">
                {{ 'kimbo-wallet.wallet-top-up' | translate }}
              </button>
              <button
                class="h-12"
                mat-button
                color="primary"
                [disabled]="isTransitioning"
                type="button"
                (click)="openDiscountModal()">
                <app-spinner
                  *ngIf="isTransitioning"
                  class="inline-block h-6 w-6 filter-blue"></app-spinner>
                <mat-icon *ngIf="!isTransitioning" class="mr-2"
                  >card_giftcard</mat-icon
                >
                <span *ngIf="!isTransitioning" class="underline">{{
                  'common.activate-code-here-label' | translate
                }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="md:flex md:items-stretch">
      <div class="flex-grow">
        <h2
          class="font-semibold {{
            !incomingGroupId ? 'mt-8 text-xl sm:text-2xl' : 'text-lg sm:text-xl'
          }}  text-navy">
          {{ 'kimbo-wallet.wallet-transaction' | translate }}
        </h2>
      </div>
    </div>
    <div
      class="flex flex-col gap-y-1 my-4 sm:flex-row sm:justify-start {{
        isTransitioning && 'opacity-80 pointer-events-none'
      }}">
      <app-global-dropdown
        *ngIf="optionPeriodDropdown"
        (triggerEvent)="handlePeriodDropdownEvent($event)"
        [pickValue]="filterPickedValue"
        [options]="optionPeriodDropdown"></app-global-dropdown>
      <button
        *ngIf="period !== 'all'"
        (click)="handleCustomPeriod()"
        type="button"
        class="flex items-center gap-2 text-sm w-fit text-blue font-semibold bg-white focus:outline-none hover:bg-kimbo-blue hover:bg-opacity-10 focus:ring-4 focus:ring-blue-800 rounded-lg px-5 py-2.5 me-2 mb-2">
        <mat-icon color="primary">calendar_today</mat-icon>
        <span>{{ startDateToDisplay }}</span>
        -
        <span>{{ endDateToDisplay }}</span>
      </button>
    </div>

    <div
      *ngIf="!isTransitioning && !isLoading && transactions?.length === 0"
      class="w-full mt-8">
      <div
        class="flex flex-col justify-center h-full items-center content-center text-center">
        <img
          appImg
          src="assets/images/transaction.png"
          class="w-36 grayscale"
          alt="transaction" />
        <p class="my-8 text-gray text-lg font-bold md:text-2xl text-center">
          {{ 'hcp-home.no-transactions' | translate }}
        </p>
      </div>
    </div>
    <div class="{{ isLoading ? 'opacity-50 pointer-events-none' : '' }}">
      <ol class="relative">
        <div *ngFor="let t of transactions">
          <li class="mb-8 ml-3 sm:ml-6 relative">
            <span
              class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white dark:ring-gray-900">
              <app-avatar
                [classNames]="'w-6 h-6'"
                [withFrame]="false"
                [src]="
                  t.user?.image_profil ?? t.targetable?.image_profil
                    | imageToUrl
                "></app-avatar>
            </span>
            <div
              *ngIf="t.user"
              class="text-dark-gray text-[12px] absolute -bottom-5 left-0">
              <h6>
                {{ t.user?.properties?.name }} |
                {{ t.user?.properties?.email ?? t.user?.properties?.tel }}
              </h6>
            </div>
            <div
              class="justify-between items-center p-4 bg-white rounded-lg border border-gray-200 shadow-sm sm:flex dark:bg-gray-700">
              <time
                class="text-xs font-normal text-gray-400 sm:order-last sm:mb-0"
                >Date: {{ toLocaleDateTime(t.created_at) }}</time
              >

              <div
                *ngIf="
                  t.type === transactionType.TOPUP ||
                  t.type === transactionType.HEALTHCAREPACKAGEREST ||
                  t.type === transactionType.DISCOUNTCARD ||
                  t.type === transactionType.DISCOUNTCARDINFLUENCER ||
                  t.type === transactionType.RECIVECREDITS ||
                  t.type === transactionType.GIFT ||
                  t.type === transactionType.BUYEDPACKAGE_CANCLED
                "
                class="text-sm font-normal text-gray-500 leading-[1.8rem] mt-3 sm:mt-0 flex items-center gap-4">
                <div
                  class="absolute top-3 right-3 xs:relative xs:top-0 xs:right-0 bg-green text-xs flex-shrink-0 w-12 h-12 flex items-center justify-center font-bold rounded-full text-white">
                  +{{ t.hc_amount }}
                </div>
                <div>
                  <div class="flex items-center gap-2">
                    <img
                      *ngIf="
                        t.type === transactionType.HEALTHCAREPACKAGEREST ||
                        t.type === transactionType.BUYEDPACKAGE_CANCLED
                      "
                      src="assets/images/refund.png"
                      alt="refund"
                      class="w-7 mr-1" />
                    <img
                      *ngIf="t.type === transactionType.TOPUP"
                      src="assets/images/top-up-e.png"
                      alt="give"
                      class="w-6 mr-1" />
                    <img
                      *ngIf="
                        t.type === transactionType.DISCOUNTCARD ||
                        t.type === transactionType.DISCOUNTCARDINFLUENCER
                      "
                      src="assets/images/offer.png"
                      alt="offer"
                      class="w-6 mr-1" />
                    <mat-icon
                      *ngIf="
                        t.type === transactionType.GIFT ||
                        t.type === transactionType.RECIVECREDITS
                      "
                      class="mr-1"
                      color="primary"
                      >card_giftcard</mat-icon
                    >
                    <span class="text-blue font-semibold">
                      {{
                        (t.type === transactionType.TOPUP &&
                        curentRole.group.type !== groupType.Hcp
                          ? 'kimbo-wallet.wallet-transaction-credits-buyed'
                          : (t.type === transactionType.TOPUP &&
                              curentRole.group.type === groupType.Hcp) ||
                            t.type === transactionType.BUYEDPACKAGE_CANCLED
                          ? 'kimbo-wallet.wallet-transaction-credits-buyed-hcp'
                          : t.type === transactionType.DISCOUNTCARD ||
                            t.type === transactionType.DISCOUNTCARDINFLUENCER
                          ? 'kimbo-wallet.wallet-transaction-discount'
                          : t.type === transactionType.RECIVECREDITS ||
                            t.type === transactionType.GIFT
                          ? 'kimbo-wallet.wallet-transaction-credits-recieve'
                          : 'hcp-home.returned-credit-from-kimboCare'
                        ) | translate
                      }}
                    </span>
                  </div>
                  <span
                    *ngIf="language === 'fr'"
                    class="bg-green text-green bg-opacity-10 text-xs font-bold mr-2 px-2.5 py-0.5 rounded"
                    >{{ t.hc_amount }}
                    {{ 'kimbo-wallet.healthcredit-label' | translate }}
                    {{ _toMoney(t.hc_amount) }}
                    {{ currency ? convertXafXof(currency.code) : '' }}</span
                  >
                  <span
                    *ngIf="language === 'en'"
                    class="bg-green text-green bg-opacity-10 text-xs font-bold mr-2 px-2.5 py-0.5 rounded"
                    >{{ t.hc_amount }}
                    {{ 'kimbo-wallet.healthcredit-label' | translate }} ({{
                      _toMoney(t.hc_amount)
                    }}
                    {{ currency ? convertXafXof(currency.code) : '' }})</span
                  >

                  <span
                    *ngIf="
                      t.type === transactionType.TOPUP &&
                      curentRole.group.type !== groupType.Hcp
                    "
                    [innerHTML]="
                      'kimbo-wallet.wallet-transaction-credits-buyed-group'
                        | translate
                          : {
                              group: t.targetable.name
                            }
                    "></span>
                  <span
                    *ngIf="
                      t.type === transactionType.BUYEDPACKAGE_CANCLED &&
                      curentRole.group.type !== groupType.Hcp
                    "
                    [innerHTML]="
                      'kimbo-wallet.wallet-transaction-credits-buyed-canceled'
                        | translate
                          : {
                              name: t.transactionable?.patient?.adresse?.name,
                              bundles: t.hcpackages | healthcarepackagesTostring
                            }
                    "></span>
                  <span
                    *ngIf="
                      t.type === transactionType.RECIVECREDITS ||
                      t.type === transactionType.GIFT
                    "
                    [innerHTML]="
                      (t.type === transactionType.RECIVECREDITS
                        ? 'kimbo-wallet.wallet-transaction-credits-buyed-benef-group'
                        : 'kimbo-wallet.wallet-transaction-gift'
                      )
                        | translate
                          : {
                              group: t.transactionable.name
                            }
                    "></span>
                  <span
                    *ngIf="
                      t.type === transactionType.DISCOUNTCARD ||
                      t.type === transactionType.DISCOUNTCARDINFLUENCER
                    "
                    [innerHTML]="
                      'kimbo-wallet.wallet-transaction-discount-part-two'
                        | translate
                    "></span>
                </div>
              </div>

              <div
                *ngIf="
                  t.type === transactionType.HEALTHCAREPACKAGEBUY ||
                  t.type === transactionType.CAMPAIGNBUY ||
                  t.type === transactionType.RECOMMENDATIONBUY
                "
                class="sm:w-9/12 text-sm font-normal text-gray-500 lex leading-[1.8rem] mt-3 sm:mt-0 flex items-center gap-4">
                <div
                  class="absolute top-3 right-3 xs:relative xs:top-0 xs:right-0 bg-red-pink flex-shrink-0 text-xs w-12 h-12 flex items-center justify-center font-bold rounded-full text-white">
                  {{ t.hc_amount }}
                </div>
                <div>
                  <div class="flex items-center gap-2">
                    <img
                      src="assets/images/give.png"
                      alt="give"
                      class="w-6 mr-1" />
                    <span class="text-blue font-semibold">
                      {{
                        'kimbo-wallet.wallet-transaction-credits-use'
                          | translate
                      }}</span
                    >
                  </div>
                  <a class="font-semibold text-blue-600">
                    <span
                      *ngIf="language === 'fr'"
                      class="bg-red text-dark-red font-bold bg-opacity-10 text-xs mr-2 px-2.5 py-0.5 rounded"
                      >{{ -1 * t.hc_amount }}
                      {{ 'kimbo-wallet.healthcredit-label' | translate }}
                      {{ _toMoney(-1 * t.hc_amount) }}
                      {{ currency ? convertXafXof(currency.code) : '' }}</span
                    >
                    <span
                      *ngIf="language === 'en'"
                      class="bg-red text-dark-red font-bold bg-opacity-10 text-xs mr-2 px-2.5 py-0.5 rounded"
                      >{{ -1 * t.hc_amount }}
                      {{ 'kimbo-wallet.healthcredit-label' | translate }} ({{
                        _toMoney(-1 * t.hc_amount)
                      }}
                      {{ currency ? convertXafXof(currency.code) : '' }})</span
                    >
                  </a>
                  <span
                    *ngIf="
                      t.type === transactionType.HEALTHCAREPACKAGEBUY ||
                      t.type === transactionType.CAMPAIGNBUY
                    "
                    [innerHTML]="
                      (t.type === transactionType.HEALTHCAREPACKAGEBUY
                        ? 'kimbo-wallet.wallet-transaction-credits-use-buyed'
                        : 'kimbo-wallet.wallet-transaction-credits-use-buyed-camp'
                      )
                        | translate
                          : {
                              title:
                                t.type === transactionType.HEALTHCAREPACKAGEBUY
                                  ? (t.hcpackages | healthcarepackagesTostring)
                                  : t.transactionable.name
                            }
                    "></span>
                  <span
                    *ngIf="t.type === transactionType.RECOMMENDATIONBUY"
                    [innerHTML]="
                      'kimbo-wallet.wallet-transaction-credits-use-buyed-recom'
                        | translate
                          : {
                              patientName:
                                t.transactionable.contact &&
                                t.transactionable.contact.adresse &&
                                t.transactionable.contact.adresse.name
                                  ? t.transactionable.contact.adresse.name
                                  : ''
                            }
                    "></span>
                </div>
              </div>

              <div
                *ngIf="t.type === transactionType.TRANSFERTCREDITS"
                class="text-sm font-normal text-gray-500 leading-[1.8rem] mt-3 sm:mt-0 flex items-center gap-4">
                <div
                  class="absolute top-3 right-3 xs:relative xs:top-0 xs:right-0 bg-red-pink flex-shrink-0 text-xs w-12 h-12 flex items-center justify-center font-bold rounded-full text-white">
                  {{ t.hc_amount }}
                </div>
                <div>
                  <div class="flex items-center gap-2">
                    <img
                      src="assets/images/charity.png"
                      alt="give"
                      class="w-6 mr-1" />
                    <span class="text-blue font-semibold">
                      {{
                        'kimbo-wallet.wallet-transaction-credits-send'
                          | translate
                      }}
                    </span>
                  </div>
                  <a class="font-semibold text-blue-600">
                    <span
                      *ngIf="language === 'fr'"
                      class="bg-red text-dark-red font-bold bg-opacity-10 text-xs mr-2 px-2.5 py-0.5 rounded"
                      >{{ -1 * t.hc_amount }}
                      {{ 'kimbo-wallet.healthcredit-label' | translate }}
                      {{ _toMoney(-1 * t.hc_amount) }}
                      {{ currency ? convertXafXof(currency.code) : '' }}</span
                    >
                    <span
                      *ngIf="language === 'en'"
                      class="bg-red text-dark-red font-bold bg-opacity-10 text-xs mr-2 px-2.5 py-0.5 rounded"
                      >{{ -1 * t.hc_amount }}
                      {{ 'kimbo-wallet.healthcredit-label' | translate }} ({{
                        _toMoney(-1 * t.hc_amount)
                      }}
                      {{ currency ? convertXafXof(currency.code) : '' }})</span
                    >
                  </a>
                  <span
                    [innerHTML]="
                      'kimbo-wallet.wallet-transaction-credits-buyed-benefit'
                        | translate
                          : {
                              user:
                                t.transactionable.owner &&
                                t.transactionable.owner.adresse &&
                                t.transactionable.owner.adresse.name
                                  ? t.transactionable.owner.adresse.name
                                  : t.transactionable.owner.email
                                  ? t.transactionable.owner.email
                                  : t.transactionable.owner.tel
                            }
                    "></span>
                </div>
              </div>
              <div
                *ngIf="t.type === transactionType.HEALTHCAREPACKAGEDEBIT"
                class="text-sm font-normal text-gray-500 leading-[1.8rem] mt-3 sm:mt-0 flex items-center gap-4">
                <div
                  class="absolute top-3 right-3 xs:relative xs:top-0 xs:right-0 bg-red-pink flex-shrink-0 text-xs w-12 h-12 flex items-center justify-center font-bold rounded-full text-white">
                  {{ t.hc_amount }}
                </div>
                <div>
                  <div class="flex items-center gap-2">
                    <img
                      src="assets/images/charity.png"
                      alt="give"
                      class="w-6 mr-1" />
                    <span class="text-blue font-semibold">
                      {{
                        'kimbo-wallet.wallet-transaction-credits-use'
                          | translate
                      }}
                    </span>
                  </div>
                  <a class="font-semibold text-blue-600">
                    <span
                      *ngIf="language === 'fr'"
                      class="bg-red text-dark-red font-bold bg-opacity-10 text-xs mr-2 px-2.5 py-0.5 rounded"
                      >{{ -1 * t.hc_amount }}
                      {{ 'kimbo-wallet.healthcredit-label' | translate }}
                      {{ _toMoney(-1 * t.hc_amount) }}
                      {{ currency ? convertXafXof(currency.code) : '' }}</span
                    >
                    <span
                      *ngIf="language === 'en'"
                      class="bg-red text-dark-red font-bold bg-opacity-10 text-xs mr-2 px-2.5 py-0.5 rounded"
                      >{{ -1 * t.hc_amount }}
                      {{ 'kimbo-wallet.healthcredit-label' | translate }} ({{
                        _toMoney(-1 * t.hc_amount)
                      }}
                      {{ currency ? convertXafXof(currency.code) : '' }})</span
                    >
                  </a>
                  <span
                    [innerHTML]="
                      'hcp-home.credit-for-treatments'
                        | translate
                          : {
                              name:
                                t.transactionable.patient &&
                                t.transactionable.patient.adresse
                                  ? t.transactionable.patient.adresse.name
                                  : ''
                            }
                    "></span>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ol>
    </div>
    <div
      *ngIf="isLoading"
      class="w-full items-start justify-start mb-4 mt-2 cursor-pointer">
      <app-skeleton-loader
        *ngFor="let number of [].constructor(2)"
        skeletonType="RECTANGLE"
        class="block mb-4"
        size="w-full h-16"></app-skeleton-loader>
    </div>
  </div>
</ng-template>
