<div
  *ngIf="type === 'INFO'"
  appClickOutside
  (clickOutside)="hide()"
  class="relative stop-click-propagation">
  <button
    mat-icon-button
    class="ml-2 stop-click-propagation"
    color="primary"
    (click)="toggleVisibility()"
    type="button">
    <mat-icon class="stop-click-propagation" svgIcon="info-outline"></mat-icon>
  </button>
  <div
    *ngIf="isVisible"
    class="absolute -right-4 w-64 whitespace-break-spaces stop-click-propagation {{
      height
    }} p-4 mt-2 text-sm bg-white rounded-lg top-full shadow-light-backdrop z-modal-backdrop text-center"
    [innerHTML]="info"></div>
</div>
<div
  *ngIf="type === 'WARNING'"
  appClickOutside
  (clickOutside)="hide()"
  class="relative z-[3] stop-click-propagation">
  <button
    class="w-8 h-8 ml-2 flex items-center justify-center bg-kimbo-ruby bg-opacity-10 text-kimbo-ruby animate-pulse focus:ring-4 focus:ring-kimbo-ruby focus:ring-opacity-40 stop-click-propagation {{
      buttonAddclassNames
    }}"
    (click)="toggleVisibility()"
    type="button">
    <i class="ri-alarm-warning-fill text-lg stop-click-propagation"></i>
  </button>
  <div
    *ngIf="isVisible"
    class="absolute w-64 p-4 mt-2 whitespace-break-spaces -left-52 text-sm bg-white rounded-lg top-full shadow-light-backdrop z-modal-backdrop stop-click-propagation">
    <div
      class="text-dark-gray text-center stop-click-propagation"
      [innerHTML]="info"></div>
  </div>
</div>
