import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from 'src/app/core/services/services.service';
import { isEmptyString, isValidPhoneNumber } from 'src/app/core/utils/core';
import { getCountriePhoneCode, getCountries } from 'src/app/core/utils/country';
import { Country, Phone, OptionObject } from 'src/app/core/utils/type';
import { CountrySelectionComponent } from '../country-dropdown/components/country-selection/country-selection.component';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { RestAuthentificationService } from 'src/app/core/rest-services/rest-services';
import { PhoneNumberService } from 'src/app/core/services/phoneNumber.service';

@Component({
  selector: 'app-phone-number-dropdown',
  templateUrl: './phone-number-dropdown.component.html',
  styleUrls: ['./phone-number-dropdown.component.scss'],
})
export class PhoneNumberDropdownComponent implements OnInit, AfterViewInit {
  @ViewChild('phoneInput', { static: false }) phoneInput!: ElementRef;
  @Input() options: OptionObject[] = [];
  @Input() classNames = '';
  @Input() name = '';
  @Input() country = '';
  @Input() placeholder = '';
  @Output() triggerEvent: EventEmitter<any> = new EventEmitter();
  @Input() pickValue?: OptionObject;
  @Input() withLabel = true;
  @Input() hasWhatapp = false;
  @Input() withWhatapp = false;
  @Input() tel = '';
  @Input() isValid?: boolean;
  @Input() caller: '_OTHER' | '_PROFIL' = '_OTHER';
  @Input() type: '_SIMPLE' | '_WITH_FLAG' = '_WITH_FLAG';
  isValidPhoneNumber = false;
  phoneModel: Phone = {
    option: this.pickValue!,
    phone: '',
  };
  error: string | undefined = '';
  rawTel = '';
  once = false;

  constructor(
    public services: ServicesService,
    public translateService: TranslateService,
    private authenticationService: RestAuthentificationService,
    private phoneNumberService: PhoneNumberService
  ) {
    this.country = this.services.sessionService.getCountry().country;
  }

  ngAfterViewInit(): void {
    if (this.type === '_SIMPLE') {
      fromEvent(this.phoneInput.nativeElement, 'input')
        .pipe(map((event: any) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(1000))
        .pipe(distinctUntilChanged())
        .subscribe(data => this.userStopTyping_Do());
    }
    this.phoneInput.nativeElement.focus();
  }

  ngOnInit(): void {
    if (this.type === '_WITH_FLAG') {
      if (
        this.tel.trim().length === 0 &&
        !this.pickValue &&
        this.options.length > 0
      ) {
        this.country = this.services.sessionService.getCountry().country;
        let countryName = '';
        let countryCode = '';
        countryCode = this.country;
        const countries: Country[] = getCountries({ longList: true });
        countries.forEach(c => {
          if (c.code.toLocaleLowerCase() === countryCode.toLocaleLowerCase()) {
            countryName = c.name;
          }
        });
        this.pickValue = {
          label: countryCode,
          value: countryName,
        };

        this.phoneModel.phoneCode = getCountriePhoneCode(this.pickValue.label);
      }
      if (this.tel.trim().length > 0) {
        this.parseGoodPhoneNumber();
      }
    }
  }

  userStopTyping_Do(): void {
    if (isValidPhoneNumber(this.tel)) {
      this.tel = this.tel.startsWith('00')
        ? this.tel.replace('00', '+')
        : this.tel;
      const countryCode = this.phoneNumberService.getCountryCodeFromPhoneNumber(
        this.phoneNumberService.formatPhoneNumber(this.tel)
      );
      if (
        this.phoneNumberService.isValidPhoneNumberForCountryCode(
          this.tel.replaceAll(' ', '').trim(),
          countryCode
        )
      ) {
        this.parseGoodPhoneNumber();
        this.isValidPhoneNumber = true;
        this.error = '';
      } else {
        this.selectedOption();
      }
    } else {
      this.error = this.translateService.instant('errors.invalid-phone-number');
    }
  }

  countryTranslation(options: OptionObject[]): OptionObject[] {
    const countries: OptionObject[] = [];
    options.forEach(country => {
      countries.push({
        label: country.label,
        value: this.translateService.instant(
          'common.' + country.label.toLowerCase()
        ),
      });
    });
    return countries;
  }

  selectedOption(): void {
    this.options = this.countryTranslation(this.options);
    this.services.modalService
      .openModal(CountrySelectionComponent, {
        width: '400px',
        height: '500px',
        data: {
          countryOptions: this.options,
        },
      })
      .subscribe((data: OptionObject) => {
        if (data) {
          if (this.type === '_SIMPLE') {
            this.isValidPhoneNumber = true;
            this.rawTel = this.pickValue
              ? String(
                  this.phoneNumberService.removeCoutryCodeInPhoneNumber(
                    this.tel,
                    this.pickValue!.label
                  )
                )
              : this.tel;
          }
          this.pickValue = data;
          this.phoneModel.phoneCode = getCountriePhoneCode(
            this.pickValue.label
          );
          if (!isValidPhoneNumber(this.tel)) {
            this.error = this.translateService.instant(
              'errors.invalid-phone-number'
            );
            this.phoneModel.option = this.pickValue!;
            this.phoneModel.phone = '';
            this.phoneModel.phoneCode = getCountriePhoneCode(
              this.pickValue.label
            );
            this.tel = this.phoneNumberService.formatPhoneNumber(
              this.rawTel,
              this.pickValue.label
            );
            if (isEmptyString(this.tel)) {
              this.tel = getCountriePhoneCode(this.pickValue.label);
              this.error = '';
            }
            this.triggerEvent.emit(this.phoneModel);
            return;
          }
          if (
            !isEmptyString(this.tel) &&
            !this.phoneNumberService.isValidPhoneNumberForCountryCode(
              this.tel,
              this.pickValue.label
            )
          ) {
            this.error = this.translateService.instant(
              'errors.invalid-phone-number-for-country-code'
            );
            this.tel = this.phoneNumberService.formatPhoneNumber(
              this.rawTel,
              this.pickValue.label
            );
            if (isEmptyString(this.tel))
              this.tel = getCountriePhoneCode(this.pickValue.label);
            this.error = undefined;
          }
          if (
            isValidPhoneNumber(this.tel) &&
            this.phoneNumberService.isValidPhoneNumberForCountryCode(
              this.tel.replaceAll(' ', '').trim(),
              this.pickValue.label
            )
          ) {
            if (this.type === '_SIMPLE') {
              this.tel = this.phoneNumberService.formatPhoneNumber(
                this.rawTel,
                this.pickValue.label
              );
            }
            this.error = undefined;
            this.phoneModel.option = this.pickValue!;
            this.phoneModel.phone = this.tel.replaceAll(' ', '').trim();
            this.phoneModel.phoneCode = getCountriePhoneCode(
              this.pickValue.label
            );
            this.triggerEvent.emit(this.phoneModel);
          } else {
            this.phoneModel.option = this.pickValue!;
            this.phoneModel.phone = '';
            this.phoneModel.phoneCode = getCountriePhoneCode(
              this.pickValue.label
            );
            this.triggerEvent.emit(this.phoneModel);
            this.error = this.translateService.instant(
              'errors.invalid-phone-number-for-country-code'
            );
          }
        }
      });
  }

  handleChanges(change: any) {
    this.tel = this.phoneNumberService.formatPhoneNumber(
      change.target.value,
      this.pickValue!.label
    );
    if (
      !isValidPhoneNumber(change.target.value) ||
      this.tel.trim().length === 0
    ) {
      if (this.tel.trim().length === 0) this.error = '';
      else
        this.error = this.translateService.instant(
          'errors.invalid-phone-number'
        );
      this.phoneModel.option = this.pickValue!;
      this.phoneModel.phone = '';
      this.phoneModel.phoneCode = getCountriePhoneCode(this.pickValue!.label);
      this.triggerEvent.emit(this.phoneModel);
      return;
    }
    this.rawTel = String(
      this.phoneNumberService.removeCoutryCodeInPhoneNumber(
        change.target.value,
        this.pickValue!.label
      )
    );
    if (
      !this.phoneNumberService.isValidPhoneNumberForCountryCode(
        change.target.value,
        this.pickValue!.label
      )
    ) {
      this.error = this.translateService.instant(
        'errors.invalid-phone-number-for-country-code'
      );
      let countryName = '';
      const countryCode = this.phoneNumberService.getCountryCodeFromPhoneNumber(
        this.tel
      );
      const countries: Country[] = getCountries({ longList: true });
      countries.forEach(c => {
        if (c.code.toLocaleLowerCase() === countryCode.toLocaleLowerCase()) {
          countryName = c.name;
        }
      });
      this.pickValue = {
        label: countryCode,
        value: countryName,
      };
      this.error = undefined;
    }
    if (
      isValidPhoneNumber(change.target.value) &&
      this.phoneNumberService.isValidPhoneNumberForCountryCode(
        change.target.value,
        this.pickValue!.label
      )
    ) {
      this.error = undefined;
    } else {
      this.error = this.translateService.instant(
        'errors.invalid-phone-number-for-country-code'
      );
      this.phoneModel.option = this.pickValue!;
      this.phoneModel.phone = '';
      this.phoneModel.phoneCode = getCountriePhoneCode(this.pickValue!.label);
      this.triggerEvent.emit(this.phoneModel);
      return;
    }

    this.phoneModel.option = this.pickValue!;
    this.phoneModel.phone = this.tel.replaceAll(' ', '').trim();
    this.phoneModel.phoneCode = getCountriePhoneCode(this.pickValue!.label);
    this.triggerEvent.emit(this.phoneModel);
  }

  parseGoodPhoneNumber(): void {
    let countryName = '';
    let countryCode = '';
    countryCode = this.phoneNumberService.getCountryCodeFromPhoneNumber(
      this.tel
    );
    if (this.tel.trim().length === 1) {
      countryCode = this.country;
    }
    if (this.country.trim().length > 0) {
      if (!isValidPhoneNumber(this.tel)) {
        this.error = this.translateService.instant(
          'errors.invalid-phone-number'
        );
      }
      //this.phoneInput.nativeElement.focus();
    }
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(c => {
      if (c.code.toLocaleLowerCase() === countryCode.toLocaleLowerCase()) {
        countryName = c.name;
      }
    });
    this.pickValue = {
      label: countryCode,
      value: countryName,
    };
    this.phoneModel = {
      option: this.pickValue,
      phone: this.tel.replaceAll(' ', '').trim(),
      phoneCode: getCountriePhoneCode(countryCode),
      hasWhatsapp: this.hasWhatapp,
    };
    this.triggerEvent.emit(this.phoneModel);
    this.tel = this.phoneNumberService.formatPhoneNumber(this.tel);
  }

  check() {
    this.phoneModel.hasWhatsapp = !this.phoneModel.hasWhatsapp;
  }
}
