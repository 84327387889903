<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="relative flex items-center justify-center w-full h-32 md:h-44 bg-kimbo-gradient rounded-xl">
  <button
    matTooltipPosition="after"
    type="button"
    mat-dialog-close
    class="absolute flex items-center justify-center w-10 h-10 text-white bg-white rounded-full hover:bg-opacity-75 ring-4 focus:ring-blue-300 focus:outline-none top-5 right-5">
    <img
      appImg
      src="assets/svg/close.svg"
      alt="close icon"
      class="w-6 h-6 text-white" />
    <span class="sr-only">Open actions menu</span>
  </button>
  <img
    appImg
    src="assets/icons/icon-512x512.png"
    class="w-32 opacity-10"
    alt="kimbo icon" />
  <div
    class="flex flex-col gap-4 sm:gap-0 sm:flex-row mt-4 items-center absolute sm:left-6 -bottom-[137px] sm:-bottom-[100px] md:-bottom-32 z-base {{
      currentRole.group.type !== groupType.Kimbo ? ' sm:mt-10' : ''
    }} sm:ml-6">
    <app-avatar
      *ngIf="!imageName"
      [classNames]="'w-32 h-32 md:w-44 md:h-44'"
      [withFrame]="true"
      [src]="user.image_profil | imageToUrl"
      [caller]="'_PROFILE_INNER'"
      [withEditSpeedDial]="true"
      (triggerEvent)="handleAvatarEvents($event)"></app-avatar>
    <app-avatar
      *ngIf="imageName"
      [classNames]="'w-32 h-32 md:w-44 md:h-44'"
      [withFrame]="true"
      [src]="imageName"
      [caller]="'_PROFILE_INNER'"
      [withEditSpeedDial]="true"
      (triggerEvent)="handleAvatarEvents($event)"></app-avatar>
    <div
      class="flex items-center justify-center gap-4 md:justify-start sm:ml-4 md:mt-2">
      <div class="flex flex-col items-center md:items-start">
        <span class="text-lg font-bold xs:text-2xl text-kimbo-blue-turquoise">
          {{
            user.data && user.data.firstName && user.data.lastName
              ? user.data.firstName + ' ' + user.data.lastName
              : ''
          }}
        </span>
        <span class="mt-2 text-sm font-blod text-dark-gray">
          {{ user.email ?? user.tel }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="sm:px-12 mt-40 sm:mt-28 md:mt-[9.75rem]">
  <div class="border-b border-gray-200">
    <ul
      class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
      <li class="mr-2">
        <a
          (click)="handleTabClick('_TAB_P_INFOS')"
          class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group cursor-pointer {{
            currentTab === '_TAB_P_INFOS'
              ? 'text-blue border-blue active'
              : 'border-transparent hover:text-gray-600 hover:border-gray-300'
          }}">
          <svg
            class="w-4 h-4 mr-2 {{
              currentTab === '_TAB_P_INFOS'
                ? 'text-blue-600'
                : 'text-gray-400 group-hover:text-gray-500'
            }}"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20">
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          {{ 'kimbo-profile.personal-information-tab-title' | translate }}
        </a>
      </li>
      <li *ngIf="showTabGiftCode" class="mr-2">
        <a
          (click)="handleTabClick('_TAB_GIFT_CODE')"
          class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group cursor-pointer {{
            currentTab === '_TAB_GIFT_CODE'
              ? 'text-blue-600 border-blue-600 active'
              : 'border-transparent hover:text-gray-600 hover:border-gray-300'
          }}"
          aria-current="page">
          <svg
            class="w-4 h-4 mr-2 {{
              currentTab === '_TAB_GIFT_CODE'
                ? 'text-blue-600'
                : 'text-gray-400 group-hover:text-gray-500'
            }}"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 18">
            <path
              d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
          </svg>
          {{ 'common.gift-card-label' | translate }}
        </a>
      </li>
      <li class="mr-2">
        <a
          (click)="handleTabClick('_TAB_SETTINGS')"
          class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group cursor-pointer {{
            currentTab === '_TAB_SETTINGS'
              ? 'text-blue-600 border-blue-600 active'
              : 'border-transparent hover:text-gray-600 hover:border-gray-300'
          }}">
          <svg
            class="w-4 h-4 mr-2 {{
              currentTab === '_TAB_SETTINGS'
                ? 'text-blue-600'
                : 'text-gray-400 group-hover:text-gray-500'
            }}"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20">
            <path
              d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
          </svg>
          {{ 'admin-layout.nav-settings-link' | translate }}
        </a>
      </li>
      <li class="mr-2">
        <a
          (click)="handleTabClick('_TAB_SECURITY')"
          class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group cursor-pointer {{
            currentTab === '_TAB_SECURITY'
              ? 'text-blue-600 border-blue-600 active'
              : 'border-transparent hover:text-gray-600 hover:border-gray-300'
          }}">
          <i
            class="ri-shield-keyhole-line text-[18px] mr-2 {{
              currentTab === '_TAB_SECURITY'
                ? 'text-blue-600'
                : 'text-gray-400 group-hover:text-gray-500'
            }}">
          </i>
          {{ 'admin-layout.nav-security-link' | translate }}
        </a>
      </li>
      <li class="mr-2">
        <a
          (click)="handleTabClick('_TAB_LEAVE_US')"
          class="inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group cursor-pointer {{
            currentTab === '_TAB_LEAVE_US'
              ? 'text-blue-600 border-blue-600 active'
              : 'border-transparent hover:text-gray-600 hover:border-gray-300'
          }}">
          <i
            class="ri-logout-box-r-line mr-2 {{
              currentTab === '_TAB_LEAVE_US'
                ? 'text-blue-600'
                : 'text-gray-400 group-hover:text-gray-500'
            }}"
            style="font-size: 1.125rem !important"></i>
          {{ 'admin-layout.nav-leave-us-link' | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
<div class="pb-10 mt-4 sm:px-12">
  <div *ngIf="currentTab === '_TAB_P_INFOS'" class="w-full">
    <form
      [formGroup]="form"
      (ngSubmit)="handleSubmit()"
      class="flex flex-wrap items-start justify-start mt-8">
      <app-form-field
        [label]="'forms.first-name-label' | translate"
        class="w-full md:w-1/2-with-gap lg:w-1/3-with-gap">
        <input
          class="w-full
      {{ input_cls.common }}"
          type="text"
          formControlName="firstName"
          [placeholder]="'forms.first-name-placeholder' | translate" />
      </app-form-field>
      <app-form-field
        [label]="'forms.last-name-label' | translate"
        class="w-full mt-6 md:mt-0 md:w-1/2-with-gap lg:w-1/3-with-gap md:ml-4">
        <input
          class="w-full
      {{ input_cls.common }}"
          type="text"
          formControlName="lastName"
          [placeholder]="'forms.last-name-placeholder' | translate" />
      </app-form-field>
      <app-form-field
        [label]="'forms.email-label' | translate"
        class="w-full mt-6 md:w-1/2-with-gap lg:w-1/3-with-gap lg:mt-0 lg:ml-4"
        [error]="
          (form.controls['email'].dirty || form.controls['email'].touched) &&
          !_isValidEmailAdress(form.value.email)
            ? ('errors.invalid-email' | translate)
            : ''
        ">
        <input
          class="w-full
      {{ input_cls.common }}"
          type="text"
          formControlName="email"
          [placeholder]="'forms.email-placeholder' | translate" />
      </app-form-field>
      <app-phone-number-dropdown
        class="w-full mt-6 md:w-1/2-with-gap lg:w-1/3-with-gap md:ml-4 lg:ml-0"
        (triggerEvent)="handlePhoneEvent($event)"
        [options]="countryListToOptionObject()"
        [hasWhatapp]="hasWhatapp"
        [tel]="tel"
        [country]="country"
        [placeholder]="'forms.phone-placeholder' | translate"
        [name]="'kimbo-create-contact.phone-label' | translate"
        caller="_PROFIL"></app-phone-number-dropdown>
      <app-country-dropdown
        class="w-full mt-6 md:w-1/2-with-gap lg:w-1/3-with-gap lg:ml-4"
        (triggerEvent)="handleCountryDropdownEvent($event)"
        [options]="countryListToOptionObjectCountry()"
        [pickValue]="pickCountryValue"
        [placeholder]="'kimbo-create-contact.country-placeholder' | translate"
        [name]="
          'kimbo-create-contact.country-label' | translate
        "></app-country-dropdown>

      <app-input-dropdown
        *ngIf="currentRole.group.type !== groupType.Hcp"
        class="w-full mt-6 md:w-1/2-with-gap lg:w-1/3-with-gap md:ml-4"
        (triggerEvent)="handleCurrencyDropdownEvent($event)"
        [options]="currencyOptions ? currencyOptions : []"
        [pickValue]="selectedCurrencie"
        [name]="'admin-fields.currency-label' | translate"></app-input-dropdown>

      <div
        class="flex flex-col items-center w-full gap-2 mt-12 xs:flex-row xs:justify-between">
        <button
          class="w-full h-12 sm:w-fit"
          mat-raised-button
          color="primary"
          [disabled]="
            !form.valid ||
            isTransitioning ||
            (!_isValidPhoneNumber(phoneModel.phone!) &&
              !_isValidEmailAdress(form.value.email))
          "
          type="submit">
          <app-spinner
            *ngIf="isTransitioning"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isTransitioning">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>
      </div>
    </form>
  </div>
  <div *ngIf="currentTab === '_TAB_GIFT_CODE'" class="w-full">
    <app-discount [isModal]="false"></app-discount>
  </div>
  <div *ngIf="currentTab === '_TAB_SETTINGS'" class="w-full mt-4">
    <ul class="flex flex-wrap items-start justify-start mb-8">
      <li class="w-full h-full md:w-1/2-with-gap lg:w-1/3-with-gap">
        <div
          class="flex items-center p-3 text-base font-bold rounded-lg text-navy bg-gray-50 group">
          <mat-icon color="primary">language</mat-icon>
          <span class="flex-1 ml-3 whitespace-nowrap">{{
            'kimbo-profile.language-label' | translate
          }}</span>
          <app-language-select></app-language-select>
        </div>
      </li>
      <li
        class="w-full mt-6 md:mt-0 md:w-1/2-with-gap lg:w-1/3-with-gap md:ml-4 h-full {{
          currentRole.group.type !== groupType.Kimbo
            ? 'opacity-50'
            : 'cursor-pointer'
        }}"
        style="height: 72px !important">
        <div
          class="flex items-center p-3 text-base font-bold text-navy rounded-lg bg-gray-50 group {{
            currentRole.group.type === groupType.Kimbo &&
              'hover:shadow hover:bg-gray-100'
          }}  h-full">
          <mat-icon *ngIf="isPushNActivated" color="primary"
            >notifications_active</mat-icon
          >
          <mat-icon *ngIf="!isPushNActivated" color="primary"
            >notifications_none</mat-icon
          >
          <span class="flex-1 ml-3 whitespace-nowrap">{{
            (isPushNActivated
              ? 'kimbo-profile.disable-push-notification'
              : 'kimbo-profile.active-push-notification'
            ) | translate
          }}</span>
          <label
            class="relative inline-flex items-center {{
              currentRole.group.type === groupType.Kimbo ? 'cursor-pointer' : ''
            }}">
            <input
              (click)="togglePushN()"
              type="checkbox"
              [disabled]="currentRole.group.type !== groupType.Kimbo"
              value=""
              class="sr-only peer"
              id="pushntoggle"
              [checked]="isPushNActivated" />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </li>
      <li
        (click)="logout()"
        class="w-full mt-6 lg:mt-0 md:w-1/2-with-gap lg:w-1/3-with-gap md:ml-0 lg:ml-4 cursor-pointer"
        style="height: 72px !important">
        <div
          class="flex items-center h-full p-3 text-base font-bold rounded-lg text-navy bg-gray-50 hover:bg-gray-100 group hover:shadow">
          <mat-icon color="warn">settings_power</mat-icon>
          <span class="flex-1 ml-3 whitespace-nowrap">{{
            'common.logout-button' | translate
          }}</span>
          <mat-icon color="primary">arrow_forward</mat-icon>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="currentTab === '_TAB_SECURITY'" class="w-full mt-4">
    <ul class="flex flex-wrap items-start justify-start">
      <li
        (click)="twoFA()"
        class="w-full h-full md:w-1/2-with-gap {{
          user.is_required_twofa && user.two_fa_active
            ? 'opacity-50'
            : 'cursor-pointer'
        }} {{ isLoading && 'opacity-50' }}"
        style="height: 72px !important">
        <div
          class="flex items-center h-full p-3 text-base font-bold rounded-lg text-navy bg-gray-50 group">
          <mat-icon color="primary">security</mat-icon>
          <span class="flex-1 ml-3">{{
            (activeTwoFactor
              ? 'kimbo-profile.disable-twoFA'
              : 'kimbo-profile.active-twoFA'
            ) | translate
          }}</span>
          <label
            class="relative inline-flex items-center {{
              user.is_required_twofa && user.two_fa_active
                ? ''
                : 'cursor-pointer'
            }}">
            <input
              (click)="twoFA()"
              type="checkbox"
              class="sr-only peer"
              id="pushntoggle"
              [checked]="activeTwoFactor"
              [disabled]="
                isLoading || (user.is_required_twofa && user.two_fa_active)
              " />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </li>
    </ul>

    <div
      *ngIf="sessions.length > 0"
      class="flex flex-col items-start justify-center pt-6">
      <div class="flex flex-row items-center justify-start">
        <i class="ri-device-line mr-2 text-md"></i>
        <span class="text-lg font-semibold">{{
          'common.device-connected' | translate
        }}</span>
        <app-info-component
          [info]="
            'common.device-connected-description' | translate
          "></app-info-component>
      </div>
      <!-- <div class="w-full mt-2 rounded-lg p-4 text-kimbo-blue bg-kimbo-blue bg-opacity-10" role="alert">
        <span class="text-sm md:text-base mt-2">
          {{'common.device-connected-description' | translate}}
        </span>
      </div> -->
    </div>

    <ul
      *ngIf="sessions.length > 0"
      class="flex flex-wrap items-start justify-start mt-4 gap-4">
      <li
        *ngFor="
          let session of showAllSessions
            ? sessions
            : sessions.slice(0, maxVisibleSessions)
        "
        class="w-full h-full md:w-1/2-with-gap lg:w-1/3-with-gap">
        <div
          [matTooltip]="'common.logout-button' | translate"
          matTooltipPosition="above"
          (click)="deleteSession(session)"
          class="cursor-pointer flex items-center justify-between h-full p-3 rounded-lg bg-gray-50 group {{
            isLoading && 'opacity-50'
          }}">
          <div class="flex items-center justify-start">
            <img
              [src]="getImageForPlatform(session.platform)"
              alt="platform image"
              srcset=""
              class="w-8 h-8" />
            <div class="flex flex-col ml-3">
              <span class="text-base font-bold text-navy">{{
                session.platform.toLowerCase().includes('windows')
                  ? session.platform
                  : session.device !== '0'
                  ? session.device
                  : session.platform !== '0'
                  ? session.platform
                  : ('common.unknown' | translate)
              }}</span>
              <span class="text-sm">{{
                'common.' + session.country_code.toLowerCase() | translate
              }}</span>
              <span class="text-sm">
                {{
                  'common.last-activity'
                    | translate
                      : { date: toLocaleDateTime(session.last_activity) }
                }}
              </span>
            </div>
          </div>
          <i
            class="ri-logout-circle-r-line mr-1 text-2xl text-kimbo-ruby cursor-pointer {{
              isLoading && 'pointer-events-none'
            }}"></i>

          <!-- <mat-icon class="text-kimbo-ruby">delete</mat-icon> -->
        </div>
      </li>
    </ul>
    <div
      *ngIf="sessions.length > maxVisibleSessions"
      class="flex items-center justify-center mt-9 w-full h-full">
      <button
        mat-button
        (click)="toggleShowMoreSessions()"
        color="primary"
        class="p-2 h-12 w-full md:w-auto flex items-center justify-center mat-stroked-button mat-button-base mat-primary border-2 rounded-lg text-center">
        <span class="whitespace-normal">
          {{
            showAllSessions
              ? ('common.show-less' | translate)
              : ('common.show-more' | translate)
          }}
        </span>
        <i
          class="{{
            !showAllSessions ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line'
          }} ml-1 text-2xl"></i>
      </button>
    </div>

    <div
      *ngIf="sessions.length > 0"
      class="flex items-center justify-center w-full mt-9">
      <button
        mat-button
        class="px-2 h-12 w-full md:w-auto flex items-center justify-center border-2 rounded-lg mat-stroked-button mat-button-base text-kimbo-ruby border-kimbo-ruby text-center {{
          isLoading && 'pointer-events-none'
        }}"
        (click)="deleteAll()">
        <span class="whitespace-normal">
          {{ 'common.delete-all' | translate }}
        </span>
        <i class="ri-logout-circle-r-line ml-1 text-2xl"></i>
      </button>
    </div>
  </div>

  <div *ngIf="currentTab === '_TAB_LEAVE_US'" class="w-full mt-4">
    <div
      class="flex flex-wrap justify-between border-1 p-4 rounded-lg border-red-pink items-center">
      <div class="flex flex-col mb-4 lg:w-3/5">
        <span class="font-semibold mb-2">
          {{ 'kimbo-profile.delete-account-button' | translate }}
        </span>
        <span class="text-sm md:text-base">
          {{ 'kimbo-profile.danger-zone-description' | translate }}
        </span>
      </div>
      <div
        (click)="openDeleteAccountDialog()"
        class="flex items-center w-full sm:w-fit py-3 cursor-pointer px-4 text-base font-semibold rounded-lg text-navy bg-gray-50 hover:bg-gray-100 group hover:shadow"
        style="height: 72px !important">
        <mat-icon color="warn">delete</mat-icon>
        <span class="flex-1 ml-3 whitespace-nowrap text-red-pink">{{
          'kimbo-profile.delete-account-button' | translate
        }}</span>
      </div>
    </div>
  </div>
</div>
