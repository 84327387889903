<!-- eslint-disable @angular-eslint/template/alt-text -->
<h2 class="text-navy" mat-dialog-title>
  <span *ngIf="data.action === '_CREATE_GROUP'">{{
    'admin-fields.create-group-label' | translate
  }}</span>
  <span *ngIf="data.action === '_EDIT_GROUP'">{{
    'admin-fields.edit-group-label' | translate : { name: data.state.label }
  }}</span>
</h2>
<button
  class="close-button"
  color="primary"
  mat-dialog-close
  mat-button
  aria-label="close icon">
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content class="relative">
  <div
    *ngIf="
      data.target === '_TRANSACTION' &&
      role &&
      role?.group?.type! !== groupeType.Hcp
    "
    class="mt-6 md:flex md:items-stretch">
    <div class="flex-grow">
      <div class="p-4 rounded-2xl bg-light-gray md:p-8 md:rounded-4xl">
        <div
          class="flex flex-col items-start justify-between md:flex-row md:items-center">
          <div
            *ngIf="isFetchingWallet"
            class="flex items-center justify-center w-full h-full">
            <app-spinner class="inline-block w-8 h-8 ml-4"></app-spinner>
          </div>
          <div
            *ngIf="!isFetchingWallet"
            class="flex items-center justify-start">
            <app-image-render
              img="assets/svg/wallet.svg"
              class="w-14"></app-image-render>
            <div
              *ngIf="wallet && currentRole.group.type !== groupeType.Admin"
              class="flex flex-col items-end justify-center font-bold text-navy">
              <p class="text-lg">
                {{ _toMoney(wallet.amount) }}
                {{ currency ? currency.code : '' }}
              </p>
              <p *ngIf="wallet" class="text-[9px]">
                {{ wallet.amount }}
                {{ 'kimbo-home.healthcredit-label' | translate }}
              </p>
            </div>
            <div
              *ngIf="
                !isFetchingGroupCurrency &&
                groupUserCurrency &&
                currentRole.group.type === groupeType.Admin
              "
              class="flex flex-col items-end justify-center font-bold text-navy">
              <p class="text-lg">
                {{ _toMoney(wallet.amount) }}
                {{ currency ? currency.code : '' }}
                <span
                  *ngIf="groupUserCurrency.code !== currency.code"
                  class="text-xs font-normal"
                  >{{ 'common.my-currency' | translate }}</span
                >
              </p>
              <p
                *ngIf="groupUserCurrency.code !== currency.code"
                class="text-lg">
                {{ _toMoney_(wallet.amount, groupUserCurrency.rate) }}
                {{ groupUserCurrency.code }}
                <span class="text-xs font-normal">{{
                  'common.my-group-currency' | translate
                }}</span>
              </p>
              <p class="text-[10px] mt-[.6rem]">
                {{ wallet.amount }}
                {{ 'kimbo-home.healthcredit-label' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="isLoading"
    class="flex items-center justify-center w-full h-full py-8">
    <app-spinner class="inline-block w-8 h-8 ml-4"></app-spinner>
  </div>
  <form
    *ngIf="
      !isLoading &&
      (!_role_selected ||
        (!isLoading && _role_selected && !_role_selected.group.is_benefit))
    "
    class=""
    [formGroup]="form"
    (ngSubmit)="save()">
    <div
      class="flex items-center w-full p-4 mx-auto mt-4 text-sm rounded-lg text-kimbo-blue bg-kimbo-blue bg-opacity-10"
      role="alert">
      <div
        [inlineSVG]="'assets/svg/info_sec.svg'"
        class="flex-shrink-0 w-6 h-6 fill-current text-blue mr-3"></div>
      <span class="sr-only">Info</span>
      <div [innerHTML]="'errors.incorrect-image-format' | translate"></div>
    </div>
    <app-form-field
      class="flex justify-center w-full"
      [classNames]="'justify-center'"
      htmlFor="_PHOTO">
      <div class="my-4">
        <app-avatar
          *ngIf="!imageName && !_role_selected"
          class="w-24 h-24"
          [classNames]="'w-24 h-24'"
          [withFrame]="false"
          [src]="DEFAULT_IMAGE"></app-avatar>
        <app-avatar
          *ngIf="imageName"
          class="w-24 h-24"
          [classNames]="'w-24 h-24'"
          [withFrame]="false"
          [src]="imageName"></app-avatar>
        <app-avatar
          *ngIf="_role_selected && !imageName"
          class="w-24 h-24"
          [classNames]="'w-24 h-24'"
          [withFrame]="false"
          [src]="getGroupImageProfil()"></app-avatar>
      </div>
      <app-file-input
        *ngIf="!isLoading"
        accept=".jpg,.jpeg,.png,.gif"
        name="image"
        (changeEvent)="setFieldValue($event)"
        id="create-contact.image"
        theme="blue-outline"
        caller="_HCP">
        {{ 'kimbo-profile.image-button' | translate }}
      </app-file-input>
      <ul class="mt-4">
        <li>
          <app-file-upload
            *ngIf="displayableFileName"
            [editable]="true"
            [filename]="displayableFileName"
            (triggerEvent)="handleUploadComponentChanges($event)">
          </app-file-upload>
        </li>
      </ul>
    </app-form-field>
    <h5
      *ngIf="data.action === '_CREATE_GROUP' && data.caller === '_ADMIN'"
      class="mt-2 mb-4 text-base text-navy">
      {{ 'kimbo-layout.choose-group-type' | translate }}
    </h5>

    <ul
      *ngIf="data.action === '_CREATE_GROUP' && data.caller === '_ADMIN'"
      class="items-center w-full mb-4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
      <li
        class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r cursor-pointer">
        <div class="flex items-center pl-3">
          <input
            (click)="toggleSelection('_INDV')"
            [checked]="isIndividual"
            id="horizontal-list-radio-particular"
            type="radio"
            value=""
            name="list-radio"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer" />
          <label
            for="horizontal-list-radio-particular"
            class="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer"
            >{{ 'sign-in.before-login-individual' | translate }}
          </label>
        </div>
      </li>
      <li
        class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r cursor-pointer">
        <div class="flex items-center pl-3 cursor-pointer">
          <input
            (click)="toggleSelection('_COMP')"
            [checked]="isCompany"
            id="horizontal-list-radio-enterprise"
            type="radio"
            value=""
            name="list-radio"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer" />
          <label
            for="horizontal-list-radio-enterprise"
            class="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer"
            >{{ 'sign-in.before-login-corporate' | translate }}</label
          >
        </div>
      </li>
      <li
        class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r cursor-pointer">
        <div class="flex items-center pl-3 cursor-pointer">
          <input
            (click)="toggleSelection('_BENEF')"
            [checked]="isBenefit"
            id="horizontal-list-radio-benefits"
            type="radio"
            value=""
            name="list-radio"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer" />
          <label
            for="horizontal-list-radio-benefits"
            class="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer"
            >Benefit</label
          >
        </div>
      </li>
      <li class="w-full cursor-pointer">
        <div class="flex items-center pl-3 cursor-pointer">
          <input
            (click)="toggleSelection('_HCP')"
            [checked]="isHcp"
            id="horizontal-list-radio-hcp"
            type="radio"
            value=""
            name="list-radio"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer" />
          <label
            for="horizontal-list-radio-hcp"
            class="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer"
            >{{ 'sign-in.before-login-hcp' | translate }}
          </label>
        </div>
      </li>
    </ul>
    <app-form-field
      *ngIf="data.caller !== '_ADMIN'"
      [label]="'forms.full-name-label' | translate"
      class="block w-full mt-6">
      <input
        class="
         {{ input_cls.common }}
       {{ disabled ? input_cls.disabled : '' }}"
        type="text"
        [placeholder]="'forms.group-placeholder' | translate"
        formControlName="name"
        id="name" />
    </app-form-field>
    <app-form-field
      *ngIf="data.caller === '_ADMIN'"
      [label]="'forms.full-name-label' | translate"
      class="w-full mt-6">
      <input
        class="
         {{ input_cls.common }}
       {{ disabled ? input_cls.disabled : '' }}"
        type="text"
        formControlName="name"
        [value]="data.state.label"
        id="name" />
    </app-form-field>
    <div *ngIf="isBenefit" class="flex flex-wrap gap-4">
      <app-form-field
        [label]="'forms.domain-name-label' | translate"
        class="block w-full md:w-1/2-with-gap mt-6">
        <input
          class="
         {{ input_cls.common }}
       {{ disabled ? input_cls.disabled : '' }}"
          type="text"
          [placeholder]="data.state.label + '.com'"
          [(ngModel)]="domainName"
          [ngModelOptions]="{ standalone: true }"
          id="domainName" />
      </app-form-field>
      <app-form-field
        [label]="
          'forms.amount-top-per-emplyees-label'
            | translate : { symbol: currency.symbol }
        "
        class="w-full md:w-1/2-with-gap mt-6"
        [error]="
          _toMoney(1) > amountPeEmployee
            ? ('errors.amount-too-low'
              | translate : { minAmount: _toMoney(1) + ' ' + currency.symbol })
            : ''
        ">
        <input
          class="
         {{ input_cls.common }}
       {{ disabled ? input_cls.disabled : '' }}"
          type="number"
          [placeholder]="
            'forms.amount-top-per-emplyees-placeholder' | translate
          "
          [(ngModel)]="amountPeEmployee"
          [ngModelOptions]="{ standalone: true }"
          id="amountPeEmployee" />
      </app-form-field>
    </div>

    <div
      *ngIf="data.action === '_CREATE_GROUP'"
      class="w-full {{
        form.valid && (isIndividual || isCompany || isHcp || isBenefit)
          ? 'border-t-1 border-gray'
          : ''
      }} flex-shrink-0 mt-6">
      <div class="flex items-center justify-center w-full mt-6">
        <button
          class="h-12 w-full"
          mat-raised-button
          color="primary"
          [disabled]="
            !(
              form.valid &&
              (isIndividual || isCompany || isHcp || isBenefit)
            ) ||
            isLoading ||
            (isBenefit && (!domainName || !amountPeEmployee)) ||
            _toMoney(1) > amountPeEmployee
          "
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>
      </div>
    </div>
    <div
      *ngIf="
        data.action === '_EDIT_GROUP' &&
        _role_selected &&
        !_role_selected.group.is_benefit
      "
      class="w-full flex-col sm:items-end flex justify-center flex-shrink-0 my-6 h-24">
      <div class="flex items-center justify-between w-full">
        <button
          *ngIf="form.valid && (isIndividual || isCompany || isBenefit)"
          class="h-12"
          mat-raised-button
          color="primary"
          [disabled]="
            !(
              form.valid &&
              (isIndividual || isCompany || isHcp || isBenefit)
            ) ||
            isLoading ||
            (isBenefit && (!domainName || !amountPeEmployee))
          "
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>

        <button
          *ngIf="_role_selected && _role_selected.group.type === groupeType.Hcp"
          class="h-12"
          mat-raised-button
          color="primary"
          [disabled]="isLoading"
          type="submit">
          <app-spinner
            *ngIf="isLoading"
            class="inline-block w-6 h-6 filter-blue"></app-spinner>
          <span *ngIf="!isLoading">{{
            'forms.save-changes-button' | translate
          }}</span>
        </button>
      </div>
    </div>
  </form>
  <div
    class="mt-6 mb-4"
    *ngIf="
      data.caller === '_ADMIN' &&
      data.action === '_EDIT_GROUP' &&
      _role_selected &&
      _role_selected.group.type === groupeType.Kimbo
    ">
    <h5 class="text-lg sm:text-xl mb-4 font-semibold text-gray-900">
      {{ 'admin-layout.patient-dashboard-heading' | translate }}
    </h5>
    <div class="-my-2 overflow-x-auto">
      <div class="inline-block min-w-full py-2 align-middle">
        <div
          class="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
          <table
            class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 {{
              isLoading ? 'opacity-50 pointer-events-none' : ''
            }}">
            <thead class="bg-gray-50 dark:bg-gray-800">
              <tr>
                <th
                  scope="col"
                  class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {{ 'admin-fields.patient-label' | translate }}
                </th>
                <th
                  scope="col"
                  class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {{ 'admin-fields.health-credit-label' | translate }}
                </th>
                <th
                  scope="col"
                  class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {{ 'admin-fields.healthcare-provider-label' | translate }}
                </th>
                <th
                  scope="col"
                  class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  {{ 'admin-fields.created-at-label' | translate }}
                </th>
              </tr>
            </thead>

            <tbody
              *ngIf="!isLoading"
              class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
              <tr *ngFor="let item of buyedpackages">
                <td
                  class="px-4 py-4 text-sm text-left font-medium text-gray-500 whitespace-nowrap">
                  <a
                    class="font-medium flex justify-start gap-2 items-center w-full">
                    <app-avatar
                      *ngIf="item && item?.patient?.image_profil"
                      class="w-6 h-6 flex-shrink cursor-pointer"
                      classNames="w-6 h-6"
                      [withFrame]="false"
                      [src]="item.patient.image_profil | imageToUrl">
                    </app-avatar>
                    <div class="flex flex-col gap-1 w-full">
                      <span
                        class="text-sm whitespace-nowrap overflow-hidden overflow-ellipsis"
                        >{{
                          item?.patient?.properties
                            ? item.patient.properties.email
                            : ''
                        }}</span
                      >
                      <span class="text-xs">{{
                        item?.patient?.properties
                          ? item.patient.properties.tel
                          : ''
                      }}</span>
                    </div>
                  </a>
                </td>
                <td
                  class="px-12 py-4 text-sm text-left font-medium text-gray-500 whitespace-nowrap">
                  {{ item?.total_credit_amount }}
                </td>
                <td
                  class="px-4 py-4 text-sm text-left font-medium text-gray-500 whitespace-nowrap">
                  <a
                    class="font-medium text-sm overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {{ item?.healthcareprovider?.name }}
                  </a>
                </td>
                <td class="px-4 py-4 text-left text-sm whitespace-nowrap">
                  <div class="flex items-center gap-x-2">
                    {{ toLocaleDateTime(item.created_at) }}
                  </div>
                </td>
              </tr>
              <tr *ngIf="buyedpackages.length === 0">
                <td
                  colspan="4"
                  class="p-2 break-words flex-shrink-0 w-full text-center text-gray">
                  {{ 'forms.no-data' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            *ngIf="isLoading"
            role="status"
            class="p-6 flex justify-center items-center w-full">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-kimbo-blue"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor" />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-transaction
    *ngIf="role && data.caller === '_ADMIN'"
    class="mt-4"
    [incomingGroupId]="role.group_id">
  </app-transaction>
</mat-dialog-content>
