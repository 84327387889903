import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IContact, ContactStore, Content } from '../../models/models';

@Injectable()
export class ContactService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * Index
   * @param groupId the group identifier to which the contact belongs
   */
  public index(groupId: number): Observable<Content<IContact>> {
    return this.http.get<Content<IContact>>(
      `${this.basePath}/v1/resources/group/${groupId}/contact`,
      this.httpOptions
    );
  }

  /**
   * View
   * @param contactId the contact identifier
   * @param groupId the group identifier to which the contact belongs
   */
  public view(contactId: number, groupId: number): Observable<IContact> {
    return this.http.get<IContact>(
      `${this.basePath}/v1/resources/group/${groupId}/contact/${contactId}`,
      this.httpOptions
    );
  }

  /**
   * Store
   * @param body
   * @param groupId the group identifier to which the contact belongs
   */
  public store(body?: ContactStore): Observable<IContact> {
    return this.http.post<IContact>(
      `${this.basePath}/v1/resources/contact`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Update
   * @param body
   * @param contactId the contact identifier
   * @param groupId the group identifier to which the contact belongs
   */
  public update(contactId: number, body?: any): Observable<IContact> {
    return this.http.put<IContact>(
      `${this.basePath}/v1/resources/contact/${contactId}`,
      JSON.stringify(body),
      this.httpOptions
    );
  }

  /**
   * Delete
   * @param contactId the contact identifier
   * @param groupId the group identifier to which the contact belongs
   */
  public delete(contactId: number): Observable<IContact> {
    return this.http.delete<IContact>(
      `${this.basePath}/v1/resources/contact/${contactId}`,
      this.httpOptions
    );
  }
}
