import { GroupType } from '../enums/enums';
import { Bonus, Content, Data } from './Common';
import { IHealthcareProvider } from './HealthcareProvider';
import { ImageProfil } from './ImageProfil';
import { IUser } from './models';
import { ITransaction } from './Transaction';

export interface IGroup {
  id: number;
  name: string;
  group_id?: number;
  type: GroupType;
  data?: any[];
  created_at?: string;
  updated_at?: string;
  deleted_at?: any;
  is_benefit?: boolean;
  health_credit_amount?: string;
  first_order_bonus?: Bonus;
  image_profil?: ImageProfil;
  transactions?: ITransaction[];
  healthcareprovider?: IHealthcareProvider;
  benefit_amount_to_credit?: number;
  benefit_domain?: string;
  user: any;
  user_admin_id?: number;
}

export interface GroupPageResponse {
  status_code: number;
  groups: Content<IGroup>;
}

export interface GroupCommonResponse {
  status_code: number;
  message?: string;
  group: IGroup;
}

export class GroupStore {
  name: string;
  type: string;
  data: Data;
  create_role: boolean;
  amount_to_credit?: number;
  domain?: string;

  constructor(
    name: string,
    type: string,
    data: Data,
    create_role: boolean,
    amount_to_credit?: number,
    domain?: string
  ) {
    this.name = name;
    this.type = type;
    this.data = data;
    this.create_role = create_role;
    this.amount_to_credit = amount_to_credit;
    this.domain = domain;
  }
}

export class Group implements IGroup {
  id: number;
  name: string;
  type: GroupType;
  group_id?: number;
  data?: any[];
  created_at?: string;
  updated_at?: string;
  is_benefit?: boolean;
  deleted_at?: any;
  health_credit_amount?: string;
  image_profil?: ImageProfil;
  user: any;
  user_admin_id?: number;

  constructor(
    id: number,
    name: string,
    type: GroupType,
    group_id?: number,
    data?: any[],
    created_at?: string,
    updated_at?: string,
    deleted_at?: any,
    health_credit_amount?: string,
    image_profil?: ImageProfil,
    user_admin_id?: number
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.group_id = group_id;
    this.data = data;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.health_credit_amount = health_credit_amount;
    this.image_profil = image_profil;
    this.user_admin_id = user_admin_id;
  }
}

export interface Statistics {
  helped_patient: number;
  healthcredit_used: number;
  healthcareprovider_used: number;
}

export interface Wallet {
  amount: number;
}

export interface Invitation {
  status: 200 | 400 | 404;
  data: {
    key: string;
    identifiant: string;
  };
}
