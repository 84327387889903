import { toMoney } from 'src/app/core/utils/misc';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Currency, IBuyedpackage, IRole } from 'src/app/core/models/models';
import { ServicesService } from 'src/app/core/services/services.service';
import { PreviewImageComponent } from 'src/app/shared-components/preview-image/preview-image.component';
import { environment } from 'src/environments/environment';
import {
  HealthPackageType,
  Language,
  StatusLogs,
} from 'src/app/core/enums/enums';
import { PhoneNumberService } from 'src/app/core/services/phoneNumber.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PatientCheckOutModalComponent } from '../patient-check-out-modal/patient-check-out-modal.component';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { MetricFormModalComponent } from '../../metric-form-modal/metric-form-modal.component';
import { HcpService } from 'src/app/core/services/hcp.service';
import { FilterStatusModalComponent } from '../../filter-status-modal/filter-status-modal.component';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-patient-modal-header',
  templateUrl: './patient-modal-header.component.html',
  styleUrls: ['./patient-modal-header.component.scss'],
})
export class PatientModalHeaderComponent implements OnInit, OnDestroy {
  @Input() buyedPackage!: IBuyedpackage;
  @Input() _buyedPackage?: IBuyedpackage;
  @Input() caller:
    | '_INTREATMENT'
    | '_INCOMMING'
    | '_FINISHED'
    | '_INTREATMENT_CHECKOUT' = '_INCOMMING';
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  healthPackageType = HealthPackageType;
  currentLanguage = Language.EN;
  currency!: Currency;
  $addLogStart!: Subscription;
  $addLogSuccess!: Subscription;
  $addLogFailed!: Subscription;
  currentLogId = -1;

  constructor(
    public services: ServicesService,
    private phoneNumberService: PhoneNumberService,
    private translateService: TranslateService,
    private hcpService: HcpService
  ) {}

  ngOnInit(): void {
    this.currency = this.services.sessionService.getCurrencie();
    this.currentLanguage = this.translateService.currentLang as Language;
    this.observeStatusLogChangesOnPatientCards();
  }

  ngOnDestroy(): void {
    this.$addLogStart && this.$addLogStart.unsubscribe();
    this.$addLogSuccess && this.$addLogSuccess.unsubscribe();
    this.$addLogFailed && this.$addLogFailed.unsubscribe();
  }

  observeStatusLogChangesOnPatientCards(): void {
    this.$addLogStart = this.services.eventService.subscribe(
      'hcp:add:log:start',
      (param: { bp_id: number; status: string }) => {
        this.currentLogId = param.bp_id;
      }
    );

    this.$addLogSuccess = this.services.eventService.subscribe(
      'hcp:add:log:success',
      (param: { bp_id: number; status: string }) => {
        this.currentLogId = -1;
        this.buyedPackage.cancheckout =
          param.status === StatusLogs.TREATMENTCOMPLETED;
      }
    );

    this.$addLogFailed = this.services.eventService.subscribe(
      'hcp:add:log:failed',
      ({ bp_id: number, status: string }) => {
        this.currentLogId = -1;
      }
    );
  }

  _toMoney(amount: number): number {
    return toMoney(amount, this.currency.rate);
  }
  _formatPhoneNumber(phoneNumber: string): string {
    return this.phoneNumberService.formatPhoneNumber(phoneNumber);
  }

  around(amount: number) {
    return Math.round(amount);
  }

  openPatientCheckoutModalDialog(): void {
    if (
      this.buyedPackage.healthcarepackages_hcp[0]?.healthcarepackage.type ===
        this.healthPackageType.Campaign &&
      this.buyedPackage.metrics &&
      this.buyedPackage.metrics!.length > 0
    ) {
      this.services.modalService
        .openModal(MaterialConfirmDialogComponent, {
          width: '500px',
          height: 'auto',
          data: {
            title: this.services.translateService.instant(
              'common.warning-label'
            ),
            message: this.services.translateService.instant(
              'hcp-common.complete-campaign-metrics-msg'
            ),
            withAction: true,
            actionLabel: this.translateService.instant(
              'hcp-common.fill-add-metrics-form'
            ),
          },
        })
        .subscribe(action => {
          if (action === '_YES') {
            this.services.modalService.openModal(MetricFormModalComponent, {
              width: '800px',
              height: 'auto',
              data: {
                buyedpackage_id: this.buyedPackage.id,
              },
            });
          }
        });
    } else if (!this.buyedPackage.cancheckout) {
      this.services.modalService
        .openModal(MaterialConfirmDialogComponent, {
          width: '500px',
          height: 'auto',
          data: {
            title: this.services.translateService.instant(
              'common.warning-label'
            ),
            message: this.services.translateService.instant(
              'hcp-common.complete-patient-status-msg',
              {
                status: this.translateService.instant(
                  'common.treatment_completed'
                ),
              }
            ),
            withAction: true,
            actionLabel: this.translateService.instant(
              'hcp-common.change-patient-status'
            ),
          },
        })
        .subscribe(action => {
          if (action === '_YES') {
            const currentLog = this.hcpService.setLastLog(this._buyedPackage!);
            this.services.modalService
              .openModal(FilterStatusModalComponent, {
                width: '400px',
                height: '500px',
                data: {
                  pickValue: currentLog
                    ? currentLog
                    : this.hcpService.getFilter()[0],
                },
              })
              .subscribe((data: OptionObject) => {
                if (data) {
                  this.services.eventService.publish('patient:status:change', {
                    pickValue: data,
                    buyedPackage: this._buyedPackage,
                  });
                }
              });
          }
        });
    } else {
      this.closeModal.emit();
      this.services.modalService.openModal(PatientCheckOutModalComponent, {
        width: '1100px',
        height: 'auto',
        data: {
          state: this._buyedPackage,
        },
      });
    }
  }

  openImage() {
    if (this.buyedPackage?.patient?.image_profil.path.endsWith('unknow.png'))
      return;
    this.services.modalService.openModal(PreviewImageComponent, {
      width: '500px',
      height: 'auto',
      data: {
        image:
          environment.basePath +
          '/' +
          (this.buyedPackage!.patient!.image_profil!.path.endsWith(
            'unknow.png'
          ) && this.buyedPackage!.patient!.image
            ? this.buyedPackage!.patient!.image.path
            : this.buyedPackage!.patient!.image_profil.path),
        name: this.buyedPackage.patient?.adresse
          ? this.buyedPackage.patient.adresse.name
          : 'Image',
      },
    });
  }

  getCurrencyCode(code: string) {
    return this.services.sessionService.getCurrencyCode(code);
  }
}
