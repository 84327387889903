import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ILoginResponse,
  IVerificationResponse,
  Login,
  VerificationCode,
} from '../../models/models';

@Injectable({ providedIn: 'root' })
export class RestAuthentificationService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * LoginGuest
   * Login as Guest without verification
   * @param body
   */
  loginGuest(): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(
      `${this.basePath}/v1/authentification/login_guest`,
      this.httpOptions
    );
  }

  /**
   * FastLogin
   * FastLogin for guest users only
   * @param body
   */
  fastLogin(body: Login): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(
      `${this.basePath}/v1/authentification/fast_creation`,
      body,
      this.httpOptions
    );
  }

  /**
   * Login
   * with verification code
   * @param body
   */
  public loginWithVerificationCode(body?: Login): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(
      `${this.basePath}/v1/authentification/login`,
      body,
      this.httpOptions
    );
  }

  /**
   * Verification code
   * @param body
   */
  public getVerificationCode(
    body?: VerificationCode
  ): Observable<IVerificationResponse> {
    return this.http.post<IVerificationResponse>(
      `${this.basePath}/v1/authentification/verificationcode`,
      body,
      this.httpOptions
    );
  }

  /**
   * logout
   */
  public logout(): Observable<any> {
    return this.http.post<any>(
      `${this.basePath}/v1/authentification/logout`,
      {},
      this.httpOptions
    );
  }

  /**
   * LoginGuest
   * Login as Guest without verification
   * @param body
   */
  getlocation(): Observable<any> {
    return this.http.get<any>(
      `https://ipinfo.io/?token=d6e8ae792ffb6a`,
      this.httpOptions
    );
  }
}
