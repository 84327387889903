import { Class } from 'leaflet';

export interface Extraction {
  array: Line[];
}

export interface Line {
  id: number;
  cells: Cell[];
}

export interface Cell {
  id: number;
  value: string;
  type: string;
}

export interface ExtractedFieldsMap {
  fields: Fields[];
  model: Extraction;
  healthcareprovider_id: number;
}

export interface CellBackend {
  line_id: number;
  cell_id: number;
}

export interface LineBackend {
  line_id: number;
  fields: Fields[];
}

export interface Fields_ {
  name:
    | 'total_amount'
    | 'name_patient'
    | 'specialities_line'
    | 'specialitie_name'
    | 'amount';
  cells?: CellBackend[];
  lines?: LineBackend[];
}

export interface Fields {
  name: string;
  cells?: number[];
  line?: number;
  lines?: number[];
}

export interface OcrModelResponse {
  ocr_model: {
    data: {
      fields: Fields_[];
    };
    modelable_id: number;
    modelable_type: string;
    updated_at: string;
    created_at: string;
    id: number;
  };
}

export interface IOcrModelResponse {
  responses: Responses;
  text: string;
}

export class OrcStore {
  data: IOcrModelResponse;
  hc_amount: number;

  constructor(data: IOcrModelResponse, hc_amount: number) {
    this.data = data;
    this.hc_amount = hc_amount;
  }
}

export interface Responses {
  id: string;
  patient_name: string;
  total_amount: number;
  currencie: string;
  lines: ILine[];
}

export interface ILine {
  name: string;
  amount: number;
  doctor_name: string;
  speciality?: string;
}
