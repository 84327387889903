import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';

import { TranslateService } from '@ngx-translate/core';
import { StatusLogs } from 'src/app/core/enums/enums';
import { ServicesService } from 'src/app/core/services/services.service';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { isEmptyString } from 'src/app/core/utils/core';
import { OptionObject } from 'src/app/core/utils/type';

@Component({
  selector: 'app-filter-status-modal',
  templateUrl: './filter-status-modal.component.html',
  styleUrls: ['./filter-status-modal.component.scss'],
})
export class FilterStatusModalComponent implements OnInit, OnChanges {
  options!: OptionObject[];
  statusListToRender!: OptionObject[];
  input_cls = INPUT_CLASSES;
  @Input() classNames = '';
  @Input() pickValue?: OptionObject;
  @Input() shift = false;

  translations: any = {
    statusAtReceptionist: '',
    statusTreatmentCompleted: '',
    statusReadyForPayment: '',
    statusNotStarted: '',
    statusAtDrugstore: '',
    statusAtImagery: '',
    statusAtLaboratory: '',
    statusPrimaryCare: '',
    statusSpecialistCare: '',
    statusInpatientServices: '',
    statusCurrentServiceOver: '',
  };
  translationKeys: string[] = [
    'common.at_receptionist',
    'common.treatment_completed',
    'common.not_started',
    'common.at_drugstore',
    'common.at_imagery',
    'common.at_laboratory',
    'common.primary_care',
    'common.specialist_care',
    'common.inpatient_services',
    'common.current_service_over',
  ];
  statusLogs = StatusLogs;
  searchValue = '';

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: { pickValue: OptionObject },
    public dialogRef: MatLegacyDialogRef<FilterStatusModalComponent>,
    private services: ServicesService,
    translateService: TranslateService
  ) {
    this.pickValue = this.data.pickValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusListToRender']) {
      this.filterPickedValue();
    }
  }

  ngOnInit(): void {
    this.getTranslations();
  }

  search(event: any): void {
    this.searchValue = event.toLocaleLowerCase();
    this.statusListToRender = !isEmptyString(event)
      ? this.options
          .filter(st => st.value.toLocaleLowerCase().includes(this.searchValue))
          .filter(st => st.value !== this.pickValue?.value)
      : this.options;
  }

  selectedOption(option: OptionObject) {
    this.dialogRef.close(option);
  }

  getFilter(): void {
    this.options = [
      {
        label: this.statusLogs.NOTSTARTED,
        value: this.translations.statusNotStarted,
      },
      {
        label: this.statusLogs.ATRECEPTIONIST,
        value: this.translations.statusAtReceptionist,
      },
      {
        label: this.statusLogs.ATLABORATORY,
        value: this.translations.statusAtLaboratory,
      },
      {
        label: this.statusLogs.PRIMARYCARE,
        value: this.translations.statusPrimaryCare,
      },
      {
        label: this.statusLogs.SPECIALISTCARE,
        value: this.translations.statusSpecialistCare,
      },
      {
        label: this.statusLogs.ATIMAGERY,
        value: this.translations.statusAtImagery,
      },
      {
        label: this.statusLogs.ATDRUGSTORE,
        value: this.translations.statusAtDrugstore,
      },
      {
        label: this.statusLogs.CURRENTSERVICEOVER,
        value: this.translations.statusCurrentServiceOver,
      },
      {
        label: this.statusLogs.INPATIENTSERVICES,
        value: this.translations.statusInpatientServices,
      },
      {
        label: this.statusLogs.TREATMENTCOMPLETED,
        value: this.translations.statusTreatmentCompleted,
      },
    ];
    this.statusListToRender = this.options;
  }

  filterPickedValue(): void {
    this.statusListToRender = this.statusListToRender.filter(
      st => st.value !== this.pickValue?.value
    );
  }

  getTranslations(): void {
    this.services.translateService
      .get(this.translationKeys)
      .subscribe(translations => {
        this.translations.statusAtReceptionist =
          translations['common.at_receptionist'];
        this.translations.statusTreatmentCompleted =
          translations['common.treatment_completed'];
        this.translations.statusNotStarted = translations['common.not_started'];
        this.translations.statusAtDrugstore =
          translations['common.at_drugstore'];
        this.translations.statusAtImagery = translations['common.at_imagery'];
        this.translations.statusAtLaboratory =
          translations['common.at_laboratory'];
        this.translations.statusPrimaryCare =
          translations['common.primary_care'];
        this.translations.statusSpecialistCare =
          translations['common.specialist_care'];
        this.translations.statusInpatientServices =
          translations['common.inpatient_services'];
        this.translations.statusCurrentServiceOver =
          translations['common.current_service_over'];
        this.getFilter();
      });
  }
}
