import { BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GroupType, Order } from 'src/app/core/enums/enums';
import { Content, Currency } from 'src/app/core/models/Common';
import { FileResponse } from 'src/app/core/models/File';
import { IRole, IUser, UserStore } from 'src/app/core/models/User';
import {
  RestAuthentificationService,
  ContactService,
  CurrencyService,
  FileService,
  UseCaseGroupService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
  BreakpointMatcherService,
  PhoneNumberService,
  ServicesService,
  SessionService,
} from 'src/app/core/services/services';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import {
  isEmptyString,
  isValidEmailAdress,
  isValidPhoneNumber,
} from 'src/app/core/utils/core';
import { getCountries } from 'src/app/core/utils/country';
import { makeCurrencytoShow } from 'src/app/core/utils/functions';
import { noop, shortenFileName } from 'src/app/core/utils/misc';
import { Country, OptionObject, Phone } from 'src/app/core/utils/type';
import { DiscountComponent } from 'src/app/shared-components/discount/discount.component';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { TotpComponent } from '../totp/totp.component';
import { UseCaseTotpService } from 'src/app/core/rest-services/usecases/usecase-totp.service';
import { ISession } from 'src/app/core/models/Session';
import { UseCaseSessionService } from 'src/app/core/rest-services/usecases/usecase-session.service';
import { toWordLocaleDateTime } from 'src/app/core/utils/datetime';
import { ContactStore, IContact } from 'src/app/core/models/Contact';
import { IPatient } from 'src/app/core/models/Patient';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  isEditing = false;
  isModalDeleteAccountOpen = false;
  public user!: IUser;
  currency!: Currency;
  pickCountryValue?: OptionObject;
  activeTwoFactor!: boolean;
  isDeleting = false;
  operation = '_CREATE';
  contactExpected?: ContactStore;
  patients: IPatient[] = [];
  currentPatient?: IPatient;

  maxVisibleSessions = 6;
  showAllSessions = false;

  currencyOptions!: OptionObject[];
  genderOptions: OptionObject[] = [
    {
      label: 'M',
      value: this.translateService.instant('common.gender-male'),
    },
    {
      label: 'F',
      value: this.translateService.instant('common.gender-female'),
    },
    {
      label: 'O',
      value: this.translateService.instant('common.gender-other'),
    },
  ];
  selectedCurrencie?: OptionObject;
  selectedGender: OptionObject = {
    label: 'M',
    value: this.translateService.instant('common.gender-male'),
  };

  form!: FormGroup;

  isLoading = false;
  isError = false;
  isSuccess = false;
  isTransitioning = false;
  currencies: Currency[] = [];
  userExpected?: UserStore;
  imageName?: string;
  phoneModel: Phone = {};
  input_cls = INPUT_CLASSES;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  file?: File;
  hasWhatapp = false;
  tel: any;
  displayableFileName?: string;
  breackpointMatche = false;
  country = '';
  isPushNActivated = false;
  showTabGiftCode = false;
  currentRole!: IRole;
  groupType = GroupType;
  currentTab = '_TAB_P_INFOS';
  langaugeSettingsIsOpen = false;
  sessions: ISession[] = [];
  language = '';

  constructor(
    public breakpointMatcherService: BreakpointMatcherService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    public authService: RestAuthentificationService,
    private fb: FormBuilder,
    private currencyService: CurrencyService,
    private usecaseGroupService: UseCaseGroupService,
    private userService: UserService,
    private translateService: TranslateService,
    private contactService: ContactService,
    private notificationService: NotificationService,
    private sessionService: SessionService,
    private services: ServicesService,
    private fileService: FileService,
    private phoneNumberService: PhoneNumberService,
    private totpService: UseCaseTotpService,
    private usecaseSessionService: UseCaseSessionService
  ) {
    this.currentRole = this.sessionService.getCurrentRole();
  }

  ngOnInit(): void {
    this.user = this.sessionService.getUser();
    this.language = this.translateService.currentLang;
    this.activeTwoFactor = this.user.two_fa_active ?? false;
    this.currency = this.sessionService.getCurrencie();
    this.selectedCurrencie = {
      label: String(this.currency.id),
      value: `${this.currency.code} (${this.currency.symbol})`,
    };
    this.form = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', Validators.pattern(this.emailPattern)],
    });
    this.fetchCurrencies();
    this.patchForm();
    this.breakpointMatcherService
      .initObserver('md')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
        } else {
          this.breackpointMatche = false;
        }
      });
    this.isPushNActivated = !!this.sessionService.getPushN();
    this.getSessions();
    const groupId = this.sessionService.getCurrentGroupId();
    this.showTabGiftCode = this.currentRole.group.type === this.groupType.Kimbo;
    if (
      this.currentRole.group.type === GroupType.Kimbo ||
      this.currentRole.group.type === GroupType.Benefit
    )
      this.fetchPatients(groupId);
  }

  toggleShowMoreSessions() {
    this.showAllSessions = !this.showAllSessions;
  }

  getSessions() {
    this.isLoading = true;
    this.usecaseSessionService.listSessions().subscribe({
      next: (res: ISession[]) => {
        this.sessions = res;
        this.isLoading = true;
      },
      error: (res: any) => {
        this.isLoading = false;
        this.notificationService.danger(
          this.translateService.instant(res.translate ?? 'errors.generic')
        );
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  fetchPatients(groupId: number): void {
    this.isTransitioning = true;
    this.usecaseGroupService
      .list_patients(
        groupId,
        1,
        Order.Descendent,
        'created_at',
        undefined,
        undefined,
        9
      )
      .subscribe({
        next: (res: Content<IPatient>) => {
          this.patients = res.data;
          this.getCurrentPatient();
        },
        error: error => {
          this.isTransitioning = false;
        },
        complete: () => {
          this.isTransitioning = false;
        },
      });
  }

  getCurrentPatient() {
    this.currentPatient = this.patients.find(
      (patient: IPatient) => patient.user_id === this.user.id
    );
  }

  patchForm(): void {
    this.form.patchValue({
      firstName: this.user.data ? this.user.data.firstName : '',
      lastName: this.user.data ? this.user.data.lastName : '',
      email: this.user.email,
    });
    this.hasWhatapp = this.user.has_whatsapp === 0 ? false : true;
    if (!this.user.tel) this.country = this.sessionService.getCountry().country;
    this.tel = this.user.tel ? this.user.tel : '';
    this.phoneModel.hasWhatsapp = this.user.has_whatsapp === 0 ? false : true;
    this.selectedGender = {
      label:
        this.user.gender === 'M' ? 'M' : this.user.gender === 'F' ? 'F' : 'O',
      value:
        this.user.gender === 'M'
          ? this.translateService.instant('common.gender-male')
          : this.user.gender === 'F'
          ? this.translateService.instant('common.gender-female')
          : this.translateService.instant('common.gender-other'),
    };
    this.selectedCurrencie = {
      label: String(this.currency.id),
      value: `${this.currency.code} (${this.currency.symbol})`,
    };
    let code = '';
    if (this.user.data?.country_code) {
      code = this.user.data?.country_code;
    }
    this.pickCountryValue = {
      label: code,
      value: this.user.data?.country ?? '',
    };
  }

  get f() {
    return this.form.controls;
  }

  fetchCurrencies(): void {
    this.isTransitioning = true;
    this.currencyService.index().subscribe({
      next: (res: Currency[]) => {
        this.currencies = res;
        this.currencies && this.initCurrenciesFilter();
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  public location(): void {
    this.authService.getlocation().subscribe({
      next: (res: any) => {
        this.country = res.country;
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  handleUploadComponentChanges(event: any): void {
    if (event === 'edit') {
      noop();
    } else if (event === 'delete') {
      this.imageName = undefined;
      this.file = undefined;
      this.displayableFileName = undefined;
    }
  }

  handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
  }

  countryListToOptionObject(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  handleSubmit(): void {
    this.updateProfil();
  }

  handlePatientSubmit(): void {
    if (this.phoneModel == null || this.phoneModel == undefined) return;
    if (
      isEmptyString(this.phoneModel?.phone) &&
      !isValidPhoneNumber(this.phoneModel?.phone)
    )
      return;
    if (this.form.invalid) return;
    if (this.pickCountryValue !== null || this.pickCountryValue !== undefined) {
      if (
        this.phoneModel?.option !== null ||
        this.phoneModel?.option !== undefined
      ) {
        if (
          this.phoneModel?.phone !== null ||
          (this.phoneModel?.phone !== undefined &&
            isValidPhoneNumber(this.phoneModel?.phone))
        ) {
          this.isTransitioning = true;
          this.isLoading = true;
          this.contactExpected = this.initExpectedContact(this.form.value);
          if (this.operation === '_CREATE') {
            this.contactService.store(this.contactExpected).subscribe({
              next: (res: IContact) => {
                if (this.file != undefined && this.file != null) {
                  this.fileService
                    .update(res.image_profil.id, this.file)
                    .subscribe({
                      next: (fileRes: FileResponse) => {
                        this.services.eventService.publish(
                          'contact-count:refresh'
                        );
                        this.services.eventService.publish('kimbo_verified', {
                          patient: res,
                          mode: 'add',
                        });
                      },
                      error: error => {
                        this.notificationService.danger(
                          this.translateService.instant(error.translate)
                        );
                        this.isLoading = false;
                      },
                      complete: () => {
                        this.isLoading = false;
                        this.isTransitioning = false;
                      },
                    });
                } else {
                  this.services.eventService.publish('contact-count:refresh');
                  this.services.eventService.publish('kimbo_verified', {
                    patient: res,
                    mode: 'add',
                  });
                }
              },
              error: () => {
                this.isLoading = false;
                this.isTransitioning = false;
              },
              complete: () => {
                this.isLoading = false;
                this.isTransitioning = false;
              },
            });
          } else if (this.operation === '_UPDATE') {
            if (this.currentPatient) {
              this.contactService
                .update(this.currentPatient!.id, this.contactExpected)
                .subscribe({
                  next: (res: IContact) => {
                    if (this.file != undefined && this.file != null) {
                      this.fileService
                        .update(res.image_profil.id, this.file)
                        .subscribe({
                          next: (fileRes: FileResponse) => {
                            this.services.eventService.publish(
                              'kimbo_verified',
                              {
                                patient: res,
                                mode: 'update',
                              }
                            );
                          },
                          error: () => {
                            this.isLoading = false;
                          },
                          complete: () => {
                            this.isLoading = false;
                            this.isTransitioning = false;
                          },
                        });
                    } else {
                      this.services.eventService.publish('kimbo_verified', {
                        patient: res,
                        mode: 'update',
                      });
                    }
                  },
                  error: error => {
                    this.notificationService.danger(
                      this.translateService.instant(error.translate)
                    );
                    this.isLoading = false;
                    this.isTransitioning = false;
                  },
                  complete: () => {
                    this.isLoading = false;
                    this.isTransitioning = false;
                  },
                });
            }
          }
        }
      }
    }
  }

  editProfilePicture() {
    if (this.file != undefined && this.file != null) {
      this.isTransitioning = true;
      this.fileService.update(this.user.image_profil.id, this.file).subscribe({
        next: () => {
          this.getUser();
          this.operation = '_UPDATE';
          this.handlePatientSubmit();
          this.notificationService.success(
            this.translateService.instant(
              'kimbo-profile.profile-saved-notification-content'
            )
          );
        },
        error: () => {
          this.isLoading = false;
          this.imageName = undefined;
          this.isTransitioning = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isTransitioning = false;
        },
      });
    }
  }

  updateProfil(): void {
    this.isTransitioning = true;
    this.isLoading = true;
    if (
      this.selectedCurrencie !== null ||
      this.selectedCurrencie !== undefined
    ) {
      this.userExpected = this.initExpectedUser(this.form.value);
      this.userService.update(this.user.id, this.userExpected).subscribe({
        next: () => {
          this.getUser();
          this.handlePatientSubmit();
          this.notificationService.success(
            this.translateService.instant(
              'kimbo-profile.profile-saved-notification-content'
            )
          );
        },
        error: (error: any) => {
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
          this.imageName = undefined;
          this.isLoading = false;
          this.isTransitioning = false;
        },
        complete: () => {
          this.isLoading = false;
          this.isTransitioning = false;
        },
      });
    }
  }

  _isValidPhoneNumber(tel: string): boolean {
    return !tel ? false : isValidPhoneNumber(tel);
  }

  _isValidEmailAdress(email: string): boolean {
    return !email ? false : isValidEmailAdress(email);
  }

  getUser(): void {
    this.isTransitioning = true;
    this.userService.view(this.user.id).subscribe({
      next: (userRes: IUser) => {
        this.user = userRes;
        this.sessionService.deleteUser();
        this.sessionService.storeUser(userRes);
        this.fetchCurrencie(userRes);
        this.activeTwoFactor = userRes.two_fa_active ?? false;
      },
      error: (err: any) => {
        this.isTransitioning = false;
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  fetchCurrencie(userRes: IUser): void {
    this.isTransitioning = true;
    this.currencyService.view(userRes.currencie_id).subscribe({
      next: (res: Currency) => {
        this.sessionService.deleteUserCurrencie();
        this.sessionService.storeUserCurrencie(res);
        this.sessionService.deleteCurrencie();
        this.sessionService.storeCurrencie(res);
        this.services.eventService.publish('user:update', userRes);
      },
      complete: () => {
        this.isTransitioning = false;
      },
    });
  }

  initExpectedContact(formValue: any): ContactStore {
    if (this.phoneModel.phone!) this.operation = '_UPDATE';
    const language_id = this.translateService.currentLang === 'fr' ? 1 : 2;
    const userPhone: string = this.user.tel ?? '';
    const userHasWhatsapp: boolean = this.user
      ?.has_whatsapp as unknown as boolean;
    const phone =
      this.phoneModel != null ? this.phoneModel.phone?.trim() : userPhone;
    const hasWhatsapp =
      this.phoneModel?.hasWhatsapp ?? userHasWhatsapp ?? false;

    const contactStore: ContactStore = new ContactStore(
      phone!,
      this.sessionService.getCurrentGroupId(),
      language_id,
      {
        first_name: formValue.firstName ?? '',
        last_name: formValue.lastName ?? '',
        name:
          formValue.firstName !== undefined && formValue.lastName !== undefined
            ? `${formValue.firstName} ${formValue.lastName}`
            : '',
        state: formValue.state ?? '',
        city: '',
        street: '',
        postcode: '',
        country:
          this.phoneModel?.option &&
          this.phoneModel.phone &&
          this.operation === '_CREATE'
            ? this.phoneModel.option.value
            : this.pickCountryValue?.value
            ? this.pickCountryValue?.value
            : this.user.data?.country,
      },
      hasWhatsapp //envoyer le has whatsapp depuis le backend sur l'objet patient
    );
    return contactStore;
  }

  initExpectedUser(formValue: any): UserStore {
    const phone =
      this.phoneModel != null ? this.phoneModel.phone?.trim() : this.user.tel;
    const hasWhatsapp = this.phoneModel?.hasWhatsapp ?? this.user?.has_whatsapp;
    const data = {
      ...formValue,
      country:
        this.phoneModel.option &&
        this.phoneModel.phone &&
        (this.pickCountryValue?.label === 'DZ' ||
          this.pickCountryValue?.label === 'AF')
          ? this.phoneModel.option.value
          : this.pickCountryValue?.value
          ? this.pickCountryValue?.value
          : this.user.data?.country
          ? this.user.data?.country
          : null,
      country_code: this.pickCountryValue?.label,
    };
    const userStore: UserStore = new UserStore(
      formValue.email?.length > 0 ? formValue.email : undefined,
      phone ?? undefined,
      this.selectedGender.label,
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      data,
      Number(this.selectedCurrencie?.label),
      undefined,
      hasWhatsapp ? 1 : 0
    );
    return userStore;
  }

  initCurrenciesFilter(): void {
    this.currencyOptions = [];
    this.currencies.forEach(currencie => {
      this.currencyOptions.push({
        label: String(currencie.id),
        value: `${this.convertXafXof(currencie.code)} (${currencie.symbol})`,
      });
    });
  }

  handleDelete(): void {
    this.isLoading = true;
    this.userService.delete(this.user.id).subscribe({
      next: (res: IUser) => {
        this.logout();
      },
      error: (error: any) => {
        this.translateService.instant('common.line-deleted-error');
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.isTransitioning = false;
      },
    });
  }

  handleCurrencyDropdownEvent(currency: OptionObject): void {
    this.selectedCurrencie = currency;
  }

  handleGenderDropdownEvent(gender: OptionObject): void {
    this.selectedGender = gender;
  }

  _formatPhoneNumber(phoneNumber: string): string {
    return this.phoneNumberService.formatPhoneNumber(phoneNumber);
  }

  logout(): void {
    this.dialogRef.close();
    this.services.sessionService.logout();
  }

  setIsEditing(): void {
    this.isEditing = this.isEditing ? false : true;
  }

  close(): void {
    this.dialogRef.close();
  }

  openDeleteAccountDialog(): void {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.handleDelete();
        }
      });
  }

  handleCountryDropdownEvent(option: any): void {
    if (option !== 'open') {
      //option.value = this.translateService.instant('common.' + option.label.toLowerCase());
      this.pickCountryValue = option;
    }
  }

  countryListToOptionObjectCountry(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  togglePushN(): void {
    if (this.currentRole.group.type === this.groupType.Kimbo) {
      this.isPushNActivated = !this.isPushNActivated;
      this.isPushNActivated
        ? this.sessionService.storePushN()
        : this.sessionService.deletePushN();
    }
  }

  openDiscountModal(): void {
    this.services.modalService.openModal(DiscountComponent, {
      width: '400px',
      height: 'auto',
    });
  }

  convertXafXof(currency: string) {
    return makeCurrencytoShow(currency);
  }

  handleTabClick(tab: string): void {
    this.currentTab = tab;
  }

  shortenFileName(longName: string): string {
    return shortenFileName(longName);
  }

  handleAvatarEvents(event: any): void {
    this.imageName = undefined;
    setTimeout(() => {
      this.imageName = event.url;
      this.file = event.file;
      this.editProfilePicture();
    }, 1000);
  }

  twoFA() {
    if (this.user.is_required_twofa && this.user.two_fa_active) {
      return;
    }

    this.isLoading = true;
    this.activeTwoFactor = this.user.two_fa_active ?? false;
    if (this.user.two_fa_active) {
      this.totpService.deactivate2FA().subscribe({
        next: (res: any) => {
          this.getUser();
          this.notificationService.success(
            this.translateService.instant('common.totp_disabled')
          );
          this.isLoading = false;
        },
        error: (error: any) => {
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
          this.isLoading = false;
        },
      });
    } else {
      this.totpService.getQrCode().subscribe({
        next: (res: { qr_code: string }) => {
          this.services.modalService
            .openModal(TotpComponent, {
              width: '750px',
              height: 'auto',
              data: {
                qr_code: res.qr_code,
                mode: 'FULL',
              },
            })
            .subscribe(() => {
              this.getUser();
            });
          this.isLoading = false;
        },
        error: (error: any) => {
          this.notificationService.danger(
            this.translateService.instant(error.translate)
          );
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
    }
  }

  toLocaleDateTime(date: string): string {
    return toWordLocaleDateTime(new Date(date), this.language);
  }

  deleteSession(session: ISession) {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
        data: {
          message: this.translateService.instant('common.delete-device'),
        },
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.isLoading = true;
          this.usecaseSessionService.deleteSessions(session.id).subscribe({
            next: () => {
              this.getSessions();
              this.notificationService.success(
                this.translateService.instant('common.device-disconnected')
              );
            },
            error: (res: any) => {
              this.isLoading = false;
              this.notificationService.danger(
                this.translateService.instant(res.translate ?? 'errors.generic')
              );
            },
            complete: () => {
              this.isLoading = false;
            },
          });
        }
      });
  }

  deleteAll() {
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
        data: {
          message: this.translateService.instant('common.delete-all-device'),
        },
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.isLoading = true;
          this.usecaseSessionService.deleteAll().subscribe({
            next: (res: any) => {
              this.getSessions();
              this.close();
              this.notificationService.success(
                this.translateService.instant('common.device-all-disconnected')
              );
            },
            error: (res: any) => {
              this.isLoading = false;
              this.notificationService.danger(
                this.translateService.instant(res.translate ?? 'errors.generic')
              );
            },
          });
        }
      });
  }

  checkPlatformIn(platform: string, platforms: string[]) {
    for (const p of platforms) {
      if (platform.toLowerCase().includes(p.toLowerCase())) return true;
    }
    return false;
  }

  getImageForPlatform(platform: string): string {
    if (this.checkPlatformIn(platform, ['android', 'blackberry'])) {
      return 'assets/svg/android.svg';
    } else if (this.checkPlatformIn(platform, ['ios', 'os x'])) {
      return 'assets/svg/apple.svg';
    } else if (this.checkPlatformIn(platform, ['windows'])) {
      return 'assets/svg/windows.svg';
    } else if (this.checkPlatformIn(platform, ['ubuntu'])) {
      return 'assets/svg/linux.svg';
    } else {
      return 'assets/svg/device.svg';
    }
  }
}
