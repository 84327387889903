<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  *ngIf="isSidebarOpen && !breackpointMatche"
  (click)="closeSideBar()"
  class="bg-black bg-opacity-50 top-0 bottom-0 right-0 left-0 absolute z-modal-backdrop"></div>
<aside
  class="{{
    isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
  }} left-0 top-0 fixed z-modal index_for_side_menu shadow-backdrop-light-l w-[320px] sidebar-menu transition-all flex h-screen flex-col bg-black duration-300 ease-linear dark:bg-boxdark">
  <!-- SIDEBAR HEADER -->
  <div
    *ngIf="!breackpointMatche"
    (click)="toggleSidebarOpen()"
    matRipple
    matRippleColor="rgba(30,104,194,.1)"
    class="rounded-full w-12 h-12 p-2 absolute flex items-center justify-center right-5 bg-white bg-opacity-40 top-[97px] cursor-pointer">
    <i class="ri-arrow-left-line text-black text-2xl"></i>
  </div>
  <div
    class="flex items-center justify-between gap-2 px-6 py-[1.375rem] lg:py-[1.625rem]">
    <a [routerLink]="'/benefit'">
      <img src="assets/images/logo-white.png" alt="Logo kimboCare" />
    </a>
  </div>
  <!-- SIDEBAR HEADER -->

  <div
    class="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
    <!-- Sidebar Menu -->
    <nav class="mt-5 px-4 py-4 lg:mt-9 lg:px-6">
      <!-- Menu Group -->
      <div>
        <h3 class="mb-4 ml-4 text-sm font-medium text-bodydark2">MENU</h3>

        <ul class="mb-6 flex flex-col gap-1.5">
          <li *ngFor="let item of navItems">
            <a
              *ngIf="item.shape === '_COMMON'"
              [routerLink]="[item.url]"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="cursor-pointer relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4">
              <i class="ri-{{ item.icon }} text-xl"></i>

              {{ item.name }}
            </a>

            <a
              *ngIf="item.shape === '_ACTION' && !item.rightsOwner"
              (click)="item.action!()"
              class="group relative cursor-pointer flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4">
              <i class="ri-{{ item.icon }} text-xl"></i>

              {{ item.name }}
            </a>
            <a
              *ngIf="item.shape === '_ACTION' && item.rightsOwner"
              (click)="item.action!()"
              class="group relative cursor-pointer flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4">
              <i class="ri-{{ item.icon }} text-xl"></i>

              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Sidebar Menu -->

    <div class="w-full absolute bottom-[0px]">
      <div class="w-full mt-auto">
        <app-health-credits-panel
          variant="_MENU_SHAPE_2"></app-health-credits-panel>
      </div>
      <a
        class="flex items-center justify-center cursor-pointer w-full h-16 bg-kimbo-turquoise bg-opacity-10 text-kimbo-turquoise hover:opacity-75"
        (click)="openProfilDialog()">
        <app-avatar
          [classNames]="'w-6 h-6'"
          [withFrame]="false"
          [withPreview]="false"
          caller="_PROFILE"
          [src]="user.image_profil | imageToUrl"></app-avatar>
        <span class="ml-2 text-sm font-medium">{{
          'common.my-account' | translate
        }}</span>
      </a>
    </div>
  </div>
</aside>
