import { Pipe, PipeTransform } from '@angular/core';
import { ImageProfil } from '../../models/ImageProfil';
import { environment } from 'src/environments/environment';
import { DEFAULT_IMAGE } from '../../utils/constant';

@Pipe({
  name: 'imageToUrl',
})
export class ImageToUrlPipe implements PipeTransform {
  baseUrl: string = environment.basePath;
  transform(value: ImageProfil, ...args: unknown[]): string {
    if (value === undefined || value === null) return DEFAULT_IMAGE;
    if (value.path === undefined || value.path === null) return DEFAULT_IMAGE;
    return `${this.baseUrl}/${value.path}`;
  }
}
