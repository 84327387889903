import { ImpactEnum } from '../../enums/enums';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IPatient,
  Content,
  IHealthcarepackage,
  IHealthcareProvider,
  ICampaign,
  Statistics,
  Wallet,
  ITransaction,
  IBuyedpackage,
} from '../../models/models';
import { ICountryModel, ICountryModelResponse } from '../../models/Country';

@Injectable()
export class UseCaseGroupService {
  protected basePath = environment.basePath;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  httpOptionsWithFromData = {
    headers: new HttpHeaders({
      enctype: 'multipart/form-data',
    }),
  };

  constructor(private http: HttpClient) {}

  /**
   * list_patients
   * @param groupId the group identifier in which the user belongs to
   */
  public list_patients(
    groupId: number,
    page?: number,
    order: string | 'ASC' | 'DESC' = 'ASC',
    order_by?: string,
    filter_status_value?: string,
    search_value?: string,
    size?: number
  ): Observable<Content<IPatient>> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(order != undefined && { order: order }),
        ...(order_by != undefined && { order_by: order_by }),
        ...(page != undefined && { page: page }),
        ...(filter_status_value != undefined && {
          filter_status_value: filter_status_value,
        }),
        ...(search_value != undefined && { search_value: search_value }),
        ...(size != undefined && { size: size }),
      },
    });

    return this.http.get<Content<IPatient>>(
      `${this.basePath}/v1/group/${groupId}/list_patients`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * list_patients
   * @param groupId the group identifier in which the user belongs to
   */
  public list_buyedpackage(groupId: number): Observable<IBuyedpackage[]> {
    let queryParameters = new HttpParams({
      fromObject: {},
    });

    return this.http.get<IBuyedpackage[]>(
      `${this.basePath}/v1/group/${groupId}/list_buyedpackage`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * list_healthpackages
   * @param groupId the group identifier in which the user belongs to
   */
  public list_healthpackages(
    groupId: number,
    type?: string,
    order?: string | 'ASC' | 'DESC',
    filter?: string,
    page?: number
  ): Observable<Content<IHealthcarepackage>> {
    let queryParameters = new HttpParams({});
    if (order || filter)
      queryParameters = new HttpParams({
        fromObject: { order: order!, filter: filter!, page: page! },
      });
    if (type)
      queryParameters = new HttpParams({
        fromObject: { type: type!, page: page! },
      });

    return this.http.get<Content<IHealthcarepackage>>(
      `${this.basePath}/v1/group/${groupId}/list_healthpackages`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * list_campaigns
   */
  public list_campaigns(group_id: number): Observable<ICampaign[]> {
    return this.http.get<ICampaign[]>(
      `${this.basePath}/v1/group/${group_id}/list_campaigns`,
      this.httpOptions
    );
  }

  /**
   * list_healthcareproviders
   * @param groupId the group identifier in which the user belongs to
   */
  public list_healthcareproviders(
    groupId: number,
    order?: string | 'ASC' | 'DESC',
    healpackage_id?: string,
    name?: boolean,
    rating?: boolean,
    page?: number,
    search_value?: string,
    city?: string[],
    country_id?: number
  ): Observable<Content<IHealthcareProvider>> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(order != null && { order: order }),
        ...(healpackage_id != null && { healpackage_id: healpackage_id }),
        ...(name != null && { name: name }),
        ...(rating != null && { rating: rating }),
        ...(page != null && { page: page }),
        ...(search_value != null && { search_value: search_value }),
        ...(city != null && { city: city }),
        ...(country_id != null && { country_id: country_id }),
      },
    });

    return this.http.get<Content<IHealthcareProvider>>(
      `${this.basePath}/v1/group/${groupId}/list_healthcareproviders`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  /**
   * statistics
   * @param groupId the group identifier in which the user belongs to
   */
  public statistics(
    groupId: number,
    period: ImpactEnum
  ): Observable<Statistics> {
    const queryParameters = new HttpParams({
      fromObject: { period: period },
    });

    return this.http.get<Statistics>(
      `${this.basePath}/v1/group/${groupId}/statistics`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * wallet
   * @param groupId the group identifier in which the user belongs to
   */
  public wallet(group_id: number): Observable<Wallet> {
    return this.http.get<Wallet>(
      `${this.basePath}/v1/group/${group_id}/wallet`,
      this.httpOptions
    );
  }

  /**
   * list_transactions
   * @param groupId the group identifier in which the user belongs to
   */
  public list_transactions(
    groupId: number,
    period:
      | 'year'
      | 'all'
      | 'month'
      | 'day'
      | 'sevent_day'
      | 'custom' = 'month',
    start_date?: string,
    end_date?: string,
    page?: number
  ): Observable<Content<ITransaction>> {
    const queryParameters = new HttpParams({
      fromObject: {
        period: period,
        ...(start_date && { start_date: start_date }),
        ...(end_date && { end_date: end_date }),
        ...(page && { page: page }),
      },
    });

    return this.http.get<Content<ITransaction>>(
      `${this.basePath}/v1/group/${groupId}/list_transactions_v2`,
      {
        params: queryParameters,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * remove_healthcarepackage
   * @param groupId the heathcare provider identifier
   */
  public list_role(groupId?: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/group/${groupId}/list_roles`,
      this.httpOptions
    );
  }

  /**
   * get country by healthcare package id
   * @param hcmId the group identifier in which the user belongs to
   */
  public getCountries(
    active?: boolean,
    hcmId?: string,
    is_onboarding?: boolean
  ): Observable<any[]> {
    const queryParameters = new HttpParams({
      fromObject: {
        ...(hcmId && { healpackage_id: hcmId }),
        ...(active && { active: active }),
        ...(is_onboarding && { is_onboarding: is_onboarding }),
      },
    });
    return this.http.get<any[]>(`${this.basePath}/v1/group/listCountries`, {
      params: queryParameters,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  /**
   * countContacts
   * @param groupId
   */

  public countContacts(groupId: number): Observable<any> {
    return this.http.get<any>(
      `${this.basePath}/v1/group/${groupId}/count_contact`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  /**
   * Update
   * Update a file
   * @param file
   * @param keymap
   * @param groupId
   */
  public massContactImport(
    groupId: number,
    file?: File,
    keymap?: any
  ): Observable<any> {
    const formParams = new FormData();
    if (file) {
      formParams.append('file', file, file.name);
    }
    if (keymap) {
      formParams.append('keymap', JSON.stringify(keymap));
    }
    return this.http.post<any>(
      `${this.basePath}/v1/group/${groupId}/mass_import_contact`,
      formParams,
      this.httpOptionsWithFromData
    );
  }
}
